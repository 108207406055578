import { GeoSearchConnectorParams } from 'instantsearch.js/es/connectors/geo-search/connectGeoSearch';
import mapboxGeoSearch from './CustomWidgets/MapboxWidget';

const showMap = (map: HTMLElement) => {
  map.classList.remove('u-hidden');
};

const hideMap = (map: HTMLElement) => {
  map.classList.add('u-hidden');
};

const toggleMap = (showMapUI: boolean) => {
  const map: HTMLElement = document.querySelector('#listingMap');

  if (showMapUI) {
    showMap(map);
  } else {
    hideMap(map);
  }
};

const initMapUI = (mapToggle: boolean) => {
  const mapToggleButton = document.querySelector(
    '#mapToggle'
  ) as HTMLButtonElement;

  if (mapToggleButton) {
    mapToggleButton.addEventListener('click', () => {
      const map = document.querySelector('#listingMap');
      const mapIsClosed = map.classList.contains('u-hidden');
      toggleMap(mapIsClosed);
    });
    toggleMap(mapToggle);
  }

  if (
    /android|iphone|ipad|ipod|blackberry|mobile|webos|windows phone/i.test(
      navigator.userAgent
    ) ||
    window.innerWidth <= 768
  ) {
    mapToggleButton.click();
  }

  return mapboxGeoSearch({
    container: document.querySelector('#listingMap'),
    initialZoom: 15
  } as GeoSearchConnectorParams);
};

export default initMapUI;
