const { createHash } = require('crypto');
/**
 * Method for handling all things GTM Tracking
 */
const initGobalGTMTracking = (): void => {
  if (sessionStorage.sessionId === undefined) {
    sessionStorage.setItem(
      'sessionId',
      `${Math.random().toString(36).substring(2)}${Math.random()
        .toString(36)
        .substring(2)}`
    );
  }

  /**
   * General CMS Actions across the site
   */
  const allActions = document.querySelectorAll('[data-action]');
  if (allActions.length) {
    allActions.forEach((action) => {
      action.addEventListener('click', (e) => {
        const anchor = e.currentTarget as HTMLAnchorElement;
        window.dataLayer.push({
          event: 'ctaLinkClick',
          ctaClicked: anchor.innerText ?? 'N/A',
          pageURL: window.location.href,
          userId: '',
          sessionId: sessionStorage.sessionId,
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    });
  }

  /**
   * "Subscribe for Updates" CTA click
   */
  const subscribeBtn = document.querySelector('[data-subscribe-updates]');
  if (subscribeBtn) {
    subscribeBtn.addEventListener('click', (e) => {
      // GTM things
      window.dataLayer.push({
        event: 'subscribeForUpdates',
        pageURL: window.location.href,
        userId: '',
        sessionId: sessionStorage.sessionId,
        hitTimeStamp: getGtmUtcString(new Date())
      });

      // Non-GTM things
      const targetID = (e.currentTarget as HTMLAnchorElement).getAttribute(
        'href'
      );
      const footerInput: HTMLInputElement = document.querySelector(
        'footer input'
      );
      document.querySelector(targetID).scrollIntoView();
      if (footerInput) {
        footerInput.focus();
      }
    });
  }

  /**
   * Primary Nav clicks
   */
  const primaryNavLinks = document.querySelectorAll(
    '[data-header-link="primary"]'
  );
  if (primaryNavLinks) {
    primaryNavLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        const anchor = e.currentTarget as HTMLAnchorElement;

        window.dataLayer.push({
          pageType: 'category',
          pageCategory: anchor.innerText,
          pageTitle: document.title,
          pageName: `category: ${document.title}`,
          pageBreadcrumb: `home > ${anchor.innerText}`,
          hitPayLoad: '50',
          pageCountryCode: 'US',
          'ecommerce.currencyCode': 'USD',
          userID: '',
          clientId: '',
          userCRMId: '',
          sessionId: sessionStorage.sessionId,
          hitTimeStamp: getGtmUtcString(new Date()),
          redirectCount: '0',
          navigationType: window.performance.getEntriesByType('navigation')[0]
            .type
        });
      });
    });
  }

  /**
   * Footer Nav clicks
   */
  const footerNavLinks = document.querySelectorAll('[data-footernav-link]');
  if (footerNavLinks) {
    footerNavLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        const anchor = e.currentTarget as HTMLAnchorElement;

        window.dataLayer.push({
          event: 'footerClick',
          footerNav: anchor.innerText ?? 'N/A',
          userId: '',
          sessionId: sessionStorage.sessionId,
          clickLocation: 'footer',
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    });
  }

  /**
   * Footer Newsletter Email submission
   */
  const footerNewsletterForm = document.querySelector(
    'footer #footerNewsletterForm'
  );

  if (footerNewsletterForm) {
    footerNewsletterForm.addEventListener('submit', () => {
      const emailInput = footerNewsletterForm.querySelector(
        'input[type="email"]'
      ) as HTMLInputElement;
      const emailHash = createHash('sha256')
        .update(emailInput.value)
        .digest('hex');

      window.dataLayer.push({
        event: 'emailSubmissions',
        userEmailHashed: emailHash,
        userId: '',
        sessionId: sessionStorage.sessionId,
        hitTimeStamp: getGtmUtcString(new Date())
      });
    });
  }

  /**
   * Newsletter Signup/Contact Form submission
   */
  let newsletterSignupForm = document.querySelector(
    '#contactus'
  ) as HTMLElement;
  if (!newsletterSignupForm) {
    newsletterSignupForm = document.querySelector(
      '#newsletterSignupForm'
    ) as HTMLElement;
  }
  if (newsletterSignupForm) {
    newsletterSignupForm.addEventListener('submit', (e) => {
      const formData = new FormData(e.target as HTMLFormElement);
      // iterate through entries...
      const formType = newsletterSignupForm.dataset.handle;
      const firstNameInput = newsletterSignupForm.querySelector(
        'input[name="firstName"]'
      ) as HTMLInputElement;
      let firstNameText = firstNameInput?.value;
      const lastNameInput = newsletterSignupForm.querySelector(
        'input[name="lastName"]'
      ) as HTMLInputElement;
      let lastNameText = lastNameInput?.value;
      if (!firstNameText) {
        // this means it's a form with "contact name"
        const contactNameInput = newsletterSignupForm.querySelector(
          'input[name="Name"]'
        ) as HTMLInputElement;
        const contactNameText = contactNameInput?.value;
        if (contactNameText?.indexOf(' ') > -1) {
          const spaceIndex = contactNameText.indexOf(' ');
          firstNameText = contactNameText.substring(0, spaceIndex);
          lastNameText = contactNameText.substring(spaceIndex + 1);
        } else {
          firstNameText = contactNameText;
          lastNameText = null;
        }
      }
      const emailInput = newsletterSignupForm.querySelector(
        'input[name="email"]'
      ) as HTMLInputElement;
      let emailHash = null;
      if (emailInput !== null) {
        emailHash = createHash('sha256')
          .update(emailInput?.value)
          .digest('hex');
      }
      const selectBoxes = newsletterSignupForm.querySelectorAll(
        '.form-check-input'
      );
      let selectedNewsletters = '';
      if (selectBoxes) {
        Object.keys(selectBoxes).forEach((key: string | number) => {
          if (!selectBoxes[key].checked) {
            return;
          }
          switch (key) {
            case '1':
              if (selectedNewsletters.length > 0) {
                selectedNewsletters += ', ';
              }
              selectedNewsletters += 'Events Edition';
              break;
            case '2':
              if (selectedNewsletters.length > 0) {
                selectedNewsletters += ', ';
              }
              selectedNewsletters += 'Deal Alerts';
              break;
            case '0':
            default:
              if (selectedNewsletters.length > 0) {
                selectedNewsletters += ', ';
              }
              selectedNewsletters += 'Blast';
              break;
          }
        });
      }
      const newsletterSelected = selectedNewsletters;
      let partnerShipInterest = null;
      if (formData.get('dealCategory')) {
        partnerShipInterest = formData.get('dealCategory');
        sessionStorage.setItem(
          'partnershipInterest',
          partnerShipInterest as string
        );
      }

      const buttonText = document.activeElement.textContent.trim();

      if (
        buttonText === null ||
        (buttonText !== 'Next' && buttonText !== 'Previous')
      ) {
        window.dataLayer.push({
          clientId: '',
          event: 'contactFormSubmission',
          formType,
          partnerShipInterest: sessionStorage.getItem('partnershipInterest'),
          firstName: firstNameText,
          hitTimeStamp: getGtmUtcString(new Date()),
          lastName: lastNameText,
          pageUrl: window.location.href,
          newsletterSelected,
          sessionId: sessionStorage.sessionId,
          userEmailHashed: emailHash,
          userId: ''
        });
        sessionStorage.removeItem('partnershipInterest');
      }
    });
  }

  /**
   * Listing Callouts (News + Events)
   */
  const listingCalloutCards = document.querySelectorAll(
    '[data-listingCallout]'
  );
  if (listingCalloutCards.length) {
    listingCalloutCards.forEach((card: HTMLElement) => {
      const articleCategory = card
        .closest('.c-wrapper')
        ?.querySelector('.u-text-goballantyne-h5')
        ?.textContent.trim()
        .toLowerCase();
      const cardType = card.dataset.listingcallout;
      const cardDate = card.querySelector('[data-date]') as HTMLElement;
      const cardEyebrow = card.querySelector('b-eyebrow') as HTMLElement;
      const cardLocation = card.querySelector('[data-location]') as HTMLElement;
      const cardTitle = card.querySelector('[data-title]') as HTMLElement;
      const dateOfArticle = cardDate?.textContent
        .replace('●', '')
        .trim()
        .toLowerCase();

      card.addEventListener('click', () => {
        if (cardType === 'news') {
          window.dataLayer.push({
            newsArticleCategory: articleCategory,
            event: 'newsArticlesClicks',
            newsArticleName: cardTitle.innerText ?? 'N/A',
            dateOfArticle: dateOfArticle ?? 'N/A',
            userId: '',
            sessionId: sessionStorage.sessionId,
            hitTimeStamp: getGtmUtcString(new Date())
          });
          return;
        }

        if (cardType === 'events') {
          const eventDateText = cardDate?.textContent
            .replace('●', '')
            .trim()
            .toLowerCase();
          window.dataLayer.push({
            event: 'upcomingEvent',
            eventName: cardTitle?.innerText ?? 'N/A',
            eventDate: eventDateText ?? 'N/A',
            eventLocation: cardLocation?.innerText ?? 'N/A',
            eventType: cardEyebrow?.innerText ?? 'N/A',
            pageURL: window.location.href,
            userId: '',
            sessionId: sessionStorage.sessionId,
            hitTimeStamp: getGtmUtcString(new Date())
          });
        }
      });
    });
  }

  /**
   * Pill and Block style goballantyneBlockCard clicks
   */
  const pillCards = document.querySelectorAll('[data-trackingpill]');
  if (pillCards) {
    pillCards.forEach((pill) => {
      const pillAnchor = pill.querySelector('a');
      pillAnchor.addEventListener('click', (e) => {
        const clickedPill = e.currentTarget as HTMLElement;
        const pillEyebrow = clickedPill.querySelector(
          '[data-eyebrow]'
        ) as HTMLElement;
        const pillSvgBackground = clickedPill.querySelector('svg #background');

        window.dataLayer.push({
          event: 'cardClicksPill',
          buttonText: pillEyebrow?.innerText.trim() ?? 'N/A',
          buttonColor: pillSvgBackground?.getAttribute('fill') ?? 'N/A',
          pageUrl: window.location.href,
          userId: '',
          sessionId: sessionStorage.sessionId,
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    });
  }
  const blockCards = document.querySelectorAll('[data-trackingcard]');
  if (blockCards) {
    blockCards.forEach((card) => {
      const buttonTitle = card
        .closest('.swiper')
        .parentElement?.querySelector('.b-headline')
        ?.textContent.trim()
        .toLowerCase();
      const cardAnchor = card.querySelector('a');
      cardAnchor.addEventListener('click', (e) => {
        const clickedCard = e.currentTarget as HTMLElement;
        const cardEyebrow = clickedCard.querySelector(
          '.b-blockCard__content__headline'
        ) as HTMLElement;
        const cardSvgBackground = clickedCard.querySelector('svg #background');

        window.dataLayer.push({
          event: 'cardClicksBlock',
          buttonText: cardEyebrow?.textContent.toLowerCase().trim() ?? 'N/A',
          buttonColor: cardSvgBackground?.getAttribute('fill') ?? 'N/A',
          buttonTitle,
          pageUrl: window.location.href,
          userId: '',
          sessionId: sessionStorage.sessionId,
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    });
  }

  /**
   * News Article Clicks
   */
  const carousels = document.querySelectorAll('.b-cardCarousel');
  carousels.forEach((carousel) => {
    const articleCategory = carousel
      .querySelector('.u-text-goballantyne-h2')
      .textContent.trim()
      .toLowerCase();
    const newsCheck = carousel
      .querySelector('.b-eyebrow')
      ?.textContent.trim()
      .toLowerCase();
    if (newsCheck) {
      const hasNews =
        newsCheck.indexOf('news') > -1 || newsCheck.indexOf('News') > -1;
      const hasSpace =
        newsCheck.indexOf('space') > -1 || newsCheck.indexOf('Space') > -1;
      if (hasNews) {
        const newsCards = carousel.querySelectorAll(
          '.swiper-slide .b-standardCard'
        );
        if (newsCards) {
          newsCards.forEach((card) => {
            const cardAnchor = card.querySelector('a');
            cardAnchor.addEventListener('click', (e) => {
              const clickedCard = e.currentTarget as HTMLElement;
              const newsArticleH5 = clickedCard.querySelector(
                '.u-text-goballantyne-h5'
              ) as HTMLElement;
              const newsArticleName = newsArticleH5.textContent
                .trim()
                .toLowerCase();

              window.dataLayer.push({
                event: 'newsArticlesClicks',
                newsArticleCategory: articleCategory,
                newsArticleName,
                pageUrl: window.location.href,
                userId: '',
                sessionId: sessionStorage.sessionId,
                hitTimeStamp: getGtmUtcString(new Date())
              });
            });
          });
        }
      } else if (hasSpace) {
        const spaceCards = carousel.querySelectorAll(
          '.swiper-slide .b-standardCard'
        );
        if (spaceCards) {
          spaceCards.forEach((card) => {
            const cardAnchor = card.querySelector('a');
            cardAnchor.addEventListener('click', (e) => {
              const clickedCard = e.currentTarget as HTMLElement;
              const listingH5 = clickedCard.querySelector(
                '.u-text-goballantyne-h5'
              ) as HTMLElement;
              const listingName = listingH5.textContent.trim().toLowerCase();
              const listingAddress = clickedCard
                .querySelector('.u-text-goballantyne-body2')
                .textContent.trim()
                .toLowerCase();

              window.dataLayer.push({
                event: 'listingClicks',
                listingCategory: articleCategory,
                listingName,
                listingAddress,
                pageUrl: window.location.href,
                userId: '',
                sessionId: sessionStorage.sessionId,
                hitTimeStamp: getGtmUtcString(new Date())
              });
            });
          });
        }
      }
    }
  });

  /**
   * Email Link Clicks
   */
  const links = document.querySelectorAll('a');
  links.forEach((link) => {
    if (link.href.indexOf('mailto:') > -1) {
      link.addEventListener('click', () => {
        let type = 'general';
        if (link.href.indexOf('leasing') > -1) {
          type = 'leasing';
        }
        window.dataLayer.push({
          event: 'emailLinkClicks',
          emailClickType: type,
          pageUrl: window.location.href,
          userId: '',
          sessionId: sessionStorage.sessionId,
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    }
  });

  /**
   * Find Space Dialog (with filters)
   */
  const findSpaceForms = document.querySelectorAll('.b-findSpaceDialog form');
  if (findSpaceForms) {
    findSpaceForms.forEach((form) => {
      form.addEventListener('submit', () => {
        const officeType = (form.querySelector(
          '#office_type'
        ) as HTMLInputElement).value;
        const sqFtValue = (form.querySelector(
          '#square_footage'
        ) as HTMLSelectElement).value;

        window.dataLayer.push({
          event: 'findSpaceInteraction',
          typeDropdown: officeType,
          sizeDropdown: sqFtValue,
          userId: '',
          sessionId: sessionStorage.sessionId,
          pageUrl: window.location.href,
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    });
  }

  return null;
};

export default initGobalGTMTracking;
