// Imports (ALPHABETICALLY)
import initAvailableSpaces from './modules/AvailableSpaces';
import initCountup from './modules/Countup';
import initFilterAccordions from './modules/FilterAccordion';
import initGobalGTMTracking from './modules/GobalGTMTracking';
import initGoBalMapMini from './modules/GoBalMapMini';
import initGobalMapModule from './modules/GoBalMapModule';
import initHeaderGoBal from './modules/HeaderGoBal';
import initHeaderNorthwood from './modules/HeaderNorthwood';
import initHeroSwiperCarousel from './modules/GoBalHeroSlider';
import initHeroVideo from './modules/HeroVideo';
import initImageCarousels from './modules/ImageCarousels';
import initImageWithHotspots from './modules/ImageWithHotspots';
import initInstantSearch from './modules/InstantSearch/InstantSearch';
import initJuicer from './modules/Juicer';
import initNewsLetterSignUpModal from './modules/GobalNewsLetterModal';
import initNwoCaseStudiesListing from './modules/InstantSearch/NwoCaseStudies';
import initMultiStepForms from './modules/MultiStepForms';
import initNwoGTMTracking from './modules/NwoGTMTracking';
import initNwoPropertyMap from './modules/NwoPropertyMap';
import initSwiperCarousels from './modules/SwiperCarousels';
import initSwitches from './modules/Lightswitch';
import initTimeline from './modules/Timeline';

const App = (): void => {
  if (window.location.hostname.includes('dev')) {
    // eslint-disable-next-line no-console
    console.log('App Starting to Load...');
  }

  // Load modules here (ALPHABETICALLY)

  // Global JS
  initFilterAccordions();
  initHeroVideo();
  initInstantSearch();
  initSwitches();
  initSwiperCarousels();
  initMultiStepForms();

  // NWO-specific JS
  if (window.location.hostname.includes('northwood')) {
    initCountup();
    initHeaderNorthwood();
    initNwoCaseStudiesListing();
    initNwoGTMTracking();
    initNwoPropertyMap();
    initTimeline();
  }

  // GoBal-specific JS (#TODO: Properly name/abstract gobal specific files and code)
  if (window.location.hostname.includes('goballantyne')) {
    initAvailableSpaces();
    initHeaderGoBal();
    initHeroSwiperCarousel();
    initImageCarousels();
    initImageWithHotspots();
    initGobalGTMTracking();
    initGobalMapModule();
    initGoBalMapMini();
    initJuicer();
    initNewsLetterSignUpModal();
  }

  if (window.location.hostname.includes('dev')) {
    // eslint-disable-next-line no-console
    console.log('App Loaded.');
  }
};

document.addEventListener('DOMContentLoaded', () => {
  App();
});
