export const PropertyCard = (item: any, { html }: { html: any }) => {
  const {
    address,
    availableSpaceFormatted,
    suitesAvailable,
    description,
    eyebrow,
    image,
    status,
    title,
    url,
    highestAvailableSpace,
    lowestAvailableSpace
  } = item;

  return html`
    <a
      href="${url}"
      class="hit | u-w-full u-flex u-flex-col u-items-start u-gap-4 u-group | sm:u-items-center sm:u-flex-row sm:u-gap-16"
      data-type="property"
    >
      <div
        class="u-relative u-w-full u-h-[12.5rem] u-overflow-hidden u-shrink-0 | sm:u-w-[12.5rem]"
      >
        <img
          src="${image}"
          class="u-w-full u-h-full u-object-cover u-object-center u-scale-[1.1] | u-transition-all u-duration-500 group-hover:u-scale-[1] group-focus:u-scale-[1]"
        />
      </div>

      <div class="c-flow c-flow--3">
        ${eyebrow
          ? html`<p
              class="u-text-goballantyne-overline u-font-secondary u-uppercase u-text-neutrals-secondary-800"
              data-category
            >
              ${eyebrow}
            </p>`
          : ``}

        <p
          data-name
          class="u-capitalize u-text-[1.375rem] u-leading-[1.45] u-font-medium | u-transition-colors u-duration-300 group-hover:u-text-primary-600 group-focus:u-text-primary-600"
        >
          ${title}
        </p>

        ${address
          ? html`<p class="u-text-base" data-address>${address}</p>`
          : ``}
        ${availableSpaceFormatted || status
          ? html`<div class="u-flex u-gap-3 u-font-medium u-items-center">
              ${availableSpaceFormatted
                ? html`<span class="u-text-base"
                    >${suitesAvailable} Suite${suitesAvailable > 1 ? 's ' : ' '}
                    Available</span
                  >`
                : ''}
            </div>`
          : ``}
        ${lowestAvailableSpace || highestAvailableSpace
          ? html`<div class="u-flex u-gap-3 u-font-medium u-items-center">
              ${lowestAvailableSpace !== highestAvailableSpace
                ? html`<span class="u-text-base"
                    >${lowestAvailableSpace} - ${highestAvailableSpace} SF
                    Available</span
                  >`
                : html`<span class="u-text-base"
                    >${lowestAvailableSpace} SF Available</span
                  >`}
            </div>`
          : ``}
        ${description
          ? html`<p class="u-text-sm u-opacity-50">${description}</p>`
          : ``}
      </div>
    </a>
  `;
};

export default PropertyCard;
