/**
 * This module is for the smaller detail-page content map with a single marker and popup
 *
 * @see https://www.figma.com/file/MGItyjhXQdgnDnJXfnrPzC/GoBallantyne-Screens?node-id=8567%3A5516
 */

// Imports
import { debounce } from 'lodash';
import mapboxgl from 'mapbox-gl';
import { MinimapData, PopupData } from '../types';
import {
  createGoBalMarkerElement,
  createPopupElement,
  mapDefaults
} from '../utils/mapUtils';

/**
 * GoBalMapMini Class
 */
class GoBalMapMini {
  /**
   * Container element for our map
   */
  container: HTMLElement;

  /**
   * Map instance
   */
  map: mapboxgl.Map;

  /**
   * Data object for our map information
   */
  mapData: MinimapData;

  /**
   * Marker for this minimap
   */
  marker: mapboxgl.Marker;

  constructor(container: HTMLElement) {
    this.container = container;
  }

  /**
   * Create the minimap
   */
  createMap(container: HTMLElement, options: Partial<mapboxgl.MapboxOptions>) {
    const map = new mapboxgl.Map({
      ...mapDefaults,
      ...options,
      container
    });

    // disable map zoom when using scroll
    map.scrollZoom.disable();

    return map;
  }

  /**
   * Creates marker for the minimap
   */
  createMarker() {
    const markerMarkup = createGoBalMarkerElement();

    const marker = new mapboxgl.Marker(markerMarkup).setLngLat({
      lng: this.mapData.location.lng,
      lat: this.mapData.location.lat
    });

    return marker;
  }

  /**
   * Creates our popup and Adds the popup and marker to the minimap
   */
  addPopup() {
    const popupEl = createPopupElement({
      entry: this.mapData.entry as PopupData
    });

    const popup = new mapboxgl.Popup({
      closeButton: false,
      /** on ipad or greater, stick to design. otherwise adjust for screensize */
      anchor: window.innerWidth >= 768 ? 'left' : 'bottom',
      offset: window.innerWidth >= 768 ? [40, 0] : [0, -20],
      className: 'b-goBalMap__popup',
      focusAfterOpen: false
    })
      .setHTML(popupEl.innerHTML)
      .setMaxWidth('330px')
      .setLngLat([this.mapData.location.lng, this.mapData.location.lng])
      .setOffset([-400, 0]);

    this.marker.setPopup(popup);
    this.marker.addTo(this.map);
    this.marker.getPopup().addTo(this.map);

    this.map.flyTo({
      center: [this.marker.getLngLat().lng, this.marker.getLngLat().lat],
      offset: [200, 0]
    });
  }

  /**
   * Initialization : Do things that need to run on init
   *
   * @return    {undefined} returns nothing, undefined
   */
  init() {
    this.mapData = JSON.parse(this.container.dataset.minimap);

    this.map = this.createMap(this.container, {
      accessToken: process.env.MAPBOX_API_KEY,
      dragPan: false,
      center: {
        lat: this.mapData.location.lat,
        lng: this.mapData.location.lng
      },
      interactive: false,
      zoom: 15,
      style: 'mapbox://styles/northwoodoffice/clrpb9v4z006y01pf86d5459b'
    });

    // Add the marker
    this.marker = this.createMarker();
    // Add the popup
    this.addPopup();

    /**
     * Add listener to window for adjusting marker position on mobile
     */
    window.addEventListener(
      'resize',
      debounce(() => {
        const windowWidth = window.innerWidth;
        const smBreakpoint = 640; // sm: 40rem = 640

        if (windowWidth < smBreakpoint) {
          this.map.flyTo({
            center: [this.marker.getLngLat().lng, this.marker.getLngLat().lat]
          });
        } else {
          this.map.flyTo({
            center: [this.marker.getLngLat().lng, this.marker.getLngLat().lat],
            offset: [200, 0]
          });
        }
      })
    );
  }
}

/**
 * Initialization, query element(s) if needed and create & init new class(es)
 *
 * @return    {undefined}          [returns nothing, initializes header]
 */
export default function initGoBalMapMini(): void {
  const minimapModules = document.querySelectorAll('[data-minimap]');

  if (!minimapModules) {
    return undefined;
  }

  minimapModules.forEach((container: HTMLElement) => {
    const minimapModule = new GoBalMapMini(container);
    minimapModule.init();
  });

  return undefined;
}
