/* eslint-disable no-nested-ternary */
/**
 * Imports
 */
import { debounce } from 'lodash';
import { MeiliSearch } from 'meilisearch';
import { IFeaturedResult, IGeneralResult } from '../types';
// import * as focusTrap from 'focus-trap';
import Header from './Header';

/**
 * Featured Result markup
 *
 * #TODO: Abstract if there's time, not very important
 *
 * @param     {IFeaturedResult} result
 *
 * @return    {string}
 */
const featuredResultMarkup = (result: IFeaturedResult) => {
  return `
    <li>
      <a
        href="${result.url ?? '#'}"
        target="${result.type === 'externalBusiness' ? '_blank' : '_self'}"
        class="u-font-primary u-text-[1.125rem] u-flex u-items-center u-gap-8 u-group"
        data-search-link
      >
        ${
          result.icon
            ? `<img
              src="/dist/img/icons/${result.icon}.svg"
              class="u-w-[4.75rem] u-h-[4.75rem]"
            />`
            : result.image
            ? `<img
              src="${result.image}"
              class="u-w-[4.75rem] u-h-[4.75rem] u-rounded-full u-object-cover"
            />`
            : ``
        }

        <div class="c-flow c-flow--2">
          ${
            result.eyebrow
              ? `<p class="u-text-sm u-text-goballantyne-overline u-font-secondary u-uppercase u-transition-colors u-text-neutrals-secondary-800">
                  ${result.eyebrow}
                </p>`
              : ``
          }

          <p class="u-text-2xl u-transition-colors u-duration-300 | group-hover:u-text-primary-600 group-focus:u-text-primary-600">
            ${result.title}
          </p>
        </div>
      </a>
    </li>
  `;
};

/**
 * General Result markup
 *
 * #TODO: Abstract if there's time, not very important
 *
 * @param     {IGeneralResult} result
 *
 * @return    {string}
 */
const generalResultMarkup = (result: IGeneralResult) => {
  return `
    <li>
      <a
        href="${result.url ?? '#'}"
        target="${result.type === 'externalBusiness' ? '_blank' : '_self'}"
        class="u-font-primary u-text-[1.125rem] u-transition-colors u-duration-300 | hover:u-text-primary-600 focus:u-text-primary-600"
        data-search-link
      >
        ${result.title}
      </a>
    </li>
  `;
};

/**
 * Class HeaderGoBal
 * - Header for GoBallantyne that extends global header functionality
 */
class HeaderGoBal extends Header {
  /**
   * Focus Trap instance
   */
  focusTrap: any = null;

  /**
   * Index filter buttons
   */
  indexFilters: HTMLButtonElement[] = null;

  /**
   * Meilisearch Client
   */
  meiliClient: MeiliSearch = new MeiliSearch({
    host: process.env.MEILI_FRONTEND_HOST,
    apiKey: process.env.MEILI_API_KEY
  });

  /**
   * Search Section within the Header
   */
  searchSection: HTMLElement;

  /**
   * Search Close button
   */
  searchClose: HTMLButtonElement;

  /**
   * Input element for Search
   */
  searchInput: HTMLInputElement;

  /**
   * Array of search results from Meili
   */
  searchResults: any[];

  /**
   * Element container for Search Results
   */
  searchResultsContainer: HTMLElement;

  /**
   * Search Trigger button
   */
  searchTrigger: HTMLButtonElement;

  /**
   * Selected index for filtering
   */
  selectedIndex: string | null;

  /**
   * Containing element that holds weather data
   */
  weatherContainer: HTMLElement;

  /**
   * Constructor
   *
   * @param     {HTMLElement}    headerEl    top-level header element
   *
   * @return    {undefined}                     returns nothing, undefined
   */
  constructor(headerEl: HTMLElement) {
    super(headerEl);
    this.searchSection = this.header.querySelector('[data-header-search]');
    this.searchClose = this.header.querySelector('[data-search-close]');
    this.searchInput = this.header.querySelector('[data-search-input]');
    this.searchResultsContainer = this.header.querySelector(
      '[data-search-results]'
    );
    this.searchTrigger = this.header.querySelector(
      '[data-header-link="search-trigger"]'
    );
    this.weatherContainer = this.header.querySelector('[data-weather]');
    this.indexFilters = Array.from(
      this.searchSection.querySelectorAll('[data-index]')
    );
  }

  /**
   * Fetch weather data from OWM
   * - Checks local storage for existing data, fetches if not
   */
  async fetchWeatherData() {
    const weatherResponse = await fetch('/api/v1/weather')
      .then((res) => res.json())
      .then((data) => data)
      .catch((err) => {
        if (window.location.hostname.includes('dev')) {
          // eslint-disable-next-line no-console
          console.error(err);
        }
      });

    const { temp } = weatherResponse.main;
    const { icon } = weatherResponse.weather[0];
    const weatherTemp = this.weatherContainer.querySelector(
      '[data-weather-temp]'
    );
    const weatherIcon = this.weatherContainer.querySelector(
      '[data-weather-icon]'
    ) as HTMLImageElement;

    weatherTemp.innerHTML = `${Math.floor(temp)}&deg;F`;
    weatherTemp.setAttribute(
      'class',
      'u-font-secondary u-font-medium u-text-xs'
    );
    weatherIcon.src = `/dist/img/weather/${icon}@2x.png`;
  }

  /**
   * Handle Search Trigger being clicked
   */
  handleSearchToggle() {
    if (!this.searchSection) {
      return;
    }

    this.searchSection.classList.toggle('active');

    if (this.searchSection.classList.contains('active')) {
      this.searchInput.focus();
    }
  }

  /**
   * Fetch search results
   *
   * @return    {null|object}
   */
  async fetchSearchResults() {
    if (this.searchInput.value === '' && this.selectedIndex === '') {
      return null;
    }

    let results = null;
    if (this.selectedIndex != null) {
      // Fetch results from specific index
      results = await this.meiliClient
        .index(this.selectedIndex)
        .search(this.searchInput.value, {
          limit: 12
        });
    } else {
      // Fetch results from all indexes
      results = await this.meiliClient
        .index('goBallantyne_global')
        .search(this.searchInput.value, {
          limit: 12
        });
    }

    // GTM Tracking
    window.dataLayer.push({
      event: 'siteSearch',
      userId: '',
      siteSearch: this.searchInput.value ?? '',
      numOfResults: results?.hits?.length ?? 0,
      pageURL: window.location.href,
      sessionId: sessionStorage.sessionId,
      hitTimeStamp: getGtmUtcString(new Date())
    });

    if (!results?.hits?.length) {
      return null;
    }

    return results;
  }

  /**
   * Render Search Results
   */
  renderSearchResults() {
    if (!this.searchResults.length) {
      return;
    }

    const featuredResults = this.searchResults.slice(0, 3);
    const generalResults = this.searchResults.slice(3, -1);
    const featuredResultsList = this.searchSection.querySelector(
      '[data-featured-list]'
    );
    const generalResultsList = this.searchSection.querySelector(
      '[data-general-list]'
    );

    // Featured Results
    let featuredResultsString = '';
    featuredResults.forEach((result) => {
      featuredResultsString += featuredResultMarkup(result);
    });

    if (featuredResultsList) {
      featuredResultsList.innerHTML = featuredResultsString;
    }

    // General Results
    let generalResultsString = '';
    generalResults.forEach((result) => {
      generalResultsString += generalResultMarkup(result);
    });

    if (generalResultsList) {
      generalResultsList.innerHTML = generalResultsString;
    }

    if (this.searchResults.length) {
      this.searchResultsContainer.classList.remove('u-hidden');
    }
  }

  /**
   * Fetch and Render search results
   */
  async fetchAndRender() {
    const results = await this.fetchSearchResults().then((res) => res);
    this.searchResults = results?.hits ?? [];
    this.renderSearchResults();
  }

  /**
   * Initialize the class
   *
   * #TODO: implement focus trap on search section as well as other tabIndexing fixes
   *
   * @return    {undefined}          returns nothing, undefined
   */
  async init() {
    super.init();

    // Fetch weather data
    this.fetchWeatherData();

    // Setup our focus trap
    // this.focusTrap = focusTrap.createFocusTrap(this.searchSection, {
    //   escapeDeactivates: () => {
    //     this.handleSearchToggle();

    //     // eslint-disable-next-line no-console
    //     console.log('escape key');

    //     return true;
    //   }
    // });

    // Listeners for search
    this.searchTrigger.addEventListener('click', () => {
      this.handleSearchToggle();
    });

    this.searchClose.addEventListener('click', () => {
      this.handleSearchToggle();
    });

    this.searchInput.addEventListener(
      'keyup',
      debounce(() => {
        this.fetchAndRender();
      }, 500)
    );

    this.indexFilters.forEach((filter) => {
      filter.addEventListener('click', async (e) => {
        const targetBtn = e.currentTarget as HTMLButtonElement;

        // If we are clicking an already active filter, deselect it
        if (this.selectedIndex === targetBtn.dataset.index) {
          this.selectedIndex = null;
          targetBtn.classList.remove('active');
          targetBtn.blur();
        } else {
          // Otherwise, set the selectedIndex and fetch new results
          this.selectedIndex = targetBtn.dataset.index;
          this.indexFilters.forEach((item) =>
            item.classList.toggle('active', item === targetBtn)
          );
          // Fetch new filtered results
          this.fetchAndRender();
        }
      });
    });

    /**
     * GTM Tracking
     */
    const primaryLinks = this.navLinks.filter(
      (link) => link.dataset.headerLink === 'primary'
    );
    const subnavLinks = this.navLinks.filter(
      (link) => link.dataset.headerLink === 'subnav'
    );
    const utilityLinks = this.navLinks.filter(
      (link) => link.dataset.headerLink === 'utility'
    );
    primaryLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        const linkText = (e.currentTarget as HTMLElement).innerText;

        window.dataLayer.push({
          event: 'navClicks',
          mainNav: linkText,
          secondaryNav: '',
          userId: '',
          sessionId: sessionStorage.sessionId,
          clickLocation: 'header',
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    });
    subnavLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        const linkText = (e.currentTarget as HTMLElement).innerText;
        const { headerParent } = (e.currentTarget as HTMLElement).dataset;

        window.dataLayer.push({
          event: 'navClicks',
          mainNav: headerParent,
          secondaryNav: linkText,
          userId: '',
          sessionId: sessionStorage.sessionId,
          clickLocation: 'header',
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    });
    utilityLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        const linkText = (e.currentTarget as HTMLElement).innerText;

        window.dataLayer.push({
          event: 'headerNavClick',
          headerNavClicked: linkText,
          userId: '',
          sessionId: sessionStorage.sessionId,
          clickLocation: 'header',
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    });
  }
}

export default function initHeaderGoBal(): void {
  let header: any = document.getElementById('header');

  if (!header || document.body.getAttribute('data-site') !== 'goballantyne') {
    return null;
  }

  header = new HeaderGoBal(header);
  header.init();

  return header;
}
