import Swiper, {
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
  Autoplay,
  EffectFade
} from 'swiper';

/**
 * Loop through all of our swiper elements and instantiate a new Swiper instance
 * - Passing options etc through data attributes in twig
 */
export default function init() {
  const logCarousels = false;
  const swiperElements = document.querySelectorAll('[data-swiper]');

  if (!swiperElements.length) return;

  swiperElements.forEach((swiperEl: HTMLElement, index: number) => {
    const swiperUuid = swiperEl.dataset.swiper;
    const swiperSelector = `[data-swiper="${swiperUuid}"]`;
    const swiperOptions = JSON.parse(swiperEl.dataset.swiperOptions);

    const swiper = new Swiper(swiperSelector, {
      modules: [
        Keyboard,
        Mousewheel,
        Navigation,
        Pagination,
        Autoplay,
        EffectFade
      ],
      keyboard: {
        enabled: true
      },
      mousewheel: {
        releaseOnEdges: true
      },
      navigation: {
        nextEl: `[data-next="${swiperUuid}"]`,
        prevEl: `[data-prev="${swiperUuid}"]`
      },
      pagination: {
        el: `[data-pagination="${swiperUuid}"]`,
        dynamicBullets: true,
        loop: true,
        clickable: true
      },
      slidesPerView: 2.5,
      spaceBetween: 50,
      threshold: 50,
      ...swiperOptions
    });

    window.swipers = window.swipers || [];
    window.swipers.push({
      uuid: swiperUuid,
      swiper
    });

    if (window.location.hostname.includes('dev') && logCarousels) {
      /* eslint-disable no-console */
      console.log(`Swiper Instance #${index}: `, swiper);
      /* eslint-enable no-console */
    }
  });
}
