import Swiper, { Autoplay, Navigation } from 'swiper';

export default function init() {
  const heroSwipers = document.querySelectorAll<HTMLElement>(
    '[data-hero-swiper]'
  );

  if (!heroSwipers.length) return;

  if (/Mobi|Android/i.test(navigator.userAgent)) {
    const sliders = document.querySelectorAll('.swiper-slide-wrapper');

    sliders.forEach((currentSlide: HTMLAnchorElement) => {
      currentSlide.addEventListener('click', () => {
        window.location.href = currentSlide.getAttribute('data-slide-url');
      });
    });
  }

  const thumbsSwiper = new Swiper('.thumbs-swiper', {
    slidesPerView: 'auto',
    loopAdditionalSlides: 3,
    loop: true,
    watchSlidesProgress: true,
    centerInsufficientSlides: false,
    spaceBetween: 0,
    slideToClickedSlide: true
  });

  // Type swiper parameter as SwiperClass
  function handleSlideChange(swiper: Swiper) {
    const { realIndex } = swiper; // Destructure realIndex
    thumbsSwiper.slideTo(realIndex);

    const activeThumbSlide = document.querySelectorAll(
      '.thumbs-swiper [data-slide-indicator]'
    )[realIndex];

    document
      .querySelectorAll('.thumbs-swiper [data-slide-indicator]')
      .forEach((el) => {
        el.classList.remove('u-active');
      });

    activeThumbSlide.classList.add('u-active');
    activeThumbSlide.classList.add('u-active-slide');

    activeThumbSlide.addEventListener('click', () => {
      if (activeThumbSlide.classList.contains('u-active-slide')) {
        window.location.href = activeThumbSlide.getAttribute('data-slide-url');
      }
    });
  }

  heroSwipers.forEach((heroSwiper) => {
    const swiperEl = heroSwiper.querySelector<HTMLElement>('.swiper');
    const indicators = heroSwiper.querySelectorAll<HTMLAnchorElement>(
      '[data-slide-indicator]'
    );

    const mainSwiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      autoplay: {
        delay: 3000,
        disableOnInteraction: false
      },
      keyboard: {
        enabled: true
      },
      loop: true,
      loopAdditionalSlides: 3,
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl: '.slider-button-next',
        prevEl: '.slider-button-prev'
      },
      threshold: 50,
      thumbs: {
        swiper: thumbsSwiper
      },
      on: {
        slideChange(swiper: Swiper) {
          // Use shorthand method and type annotation
          handleSlideChange(swiper); // Call the named function
        }
      },
      speed: 800
    });

    indicators.forEach((indicator: HTMLAnchorElement, index: number) => {
      indicator.addEventListener('click', (e) => {
        e.preventDefault();
        const { realIndex } = mainSwiper; // Destructure realIndex
        if (index !== realIndex) {
          mainSwiper.slideToLoop(index); // Use slideToLoop for looped Swipers
        }
      });
    });
  });
}
