/**
 * Imports
 */

/**
 * FilterAccordion Class
 */
class FilterAccordion {
  /**
   * Accordion container <div>
   */
  container: Element;

  /**
   * Drawer for the accordion active state
   */
  drawerContainer: HTMLElement;

  /**
   * Trigger for the accordion
   */
  trigger: HTMLElement;

  /**
   * Constructor
   */
  constructor(container: Element) {
    this.container = container;
    this.drawerContainer = this.container.querySelector('div:first-of-type');
    this.trigger = this.container.querySelector('button');
  }

  /**
   * Method for attaching our event listeners
   */
  attachListeners() {
    this.trigger.addEventListener('click', () => {
      this.container.classList.toggle('js-active');
    });
  }

  /**
   * Initialization : Attach listeners and any other methods that need to run on init
   *
   * @return    {[type]}          [return description]
   */
  init() {
    this.attachListeners();
  }
}

/**
 * Initialization, query element(s) if needed and create & init new class(es)
 *
 * @return    {undefined}          [returns nothing, initializes class]
 */
export default function initFilterAccordions(): void {
  const accordionContainers = Array.from(
    document.querySelectorAll('[data-filter-accordion]')
  );

  if (!accordionContainers) {
    return undefined;
  }

  accordionContainers.forEach((container) => {
    const module = new FilterAccordion(container);
    module.init();
  });

  return undefined;
}
