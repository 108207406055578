/**
 * This module facilitates Freeform multi-step (AJAX) forms
 * and ensures they are compatible with Snaptcha spam filtering.
 */
class MultiStepForms {
  formNode: HTMLFormElement;

  constructor(node: HTMLFormElement) {
    this.formNode = node;
    this.formNode.addEventListener(
      'freeform-ajax-success',
      async (event: any) => {
        // return early if there are no more pages
        if (event.response.isFinished) {
          return;
        }
        const { name, value } = await this.retrieveSnaptchaField();
        this.insertSnaptchaField(name, value);
      }
    );
  }

  /**
   * Get the snaptcha configured name and a new value
   */
  async retrieveSnaptchaField() {
    const resp = await fetch('/actions/snaptcha/field/get-field', {
      headers: {
        Accept: 'application/json'
      }
    });
    return resp.json();
  }

  /**
   * Insert the snaptcha values into the DOM
   */
  insertSnaptchaField(name: string, value: string): void {
    const node = document.createElement('input');
    node.type = 'hidden';
    node.name = name;
    node.value = value;
    this.formNode.appendChild(node);
  }
}

export default function initMultiStepForms() {
  const nodes = Array.from(document.querySelectorAll('[data-freeform]'));
  nodes.map((node: HTMLFormElement) => new MultiStepForms(node));
}
