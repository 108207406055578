// Imports
import mapboxgl from 'mapbox-gl';
import {
  CreateClusterElement,
  CreateGoBalMarkerElement,
  CreateNwoMarkerElement,
  CreateNWOPopupElement,
  CreatePopupElement
} from '../types';

// Default mapbox config for GoBallantyne
export const mapDefaults = {
  center: new mapboxgl.LngLat(-80.8455, 35.0469),
  zoom: 13,
  style: 'mapbox://styles/northwoodoffice/clrpb9v4z006y01pf86d5459b'
};

/**
 * Flatten map bounds for mapbox
 */
export function flattenMapBounds(map: mapboxgl.Map) {
  const bounds = map
    .getBounds()
    .toArray()
    .reduce((acc, bound) => acc.concat(bound));
  return bounds as GeoJSON.BBox;
}

/**
 * Create a cluster element for mapbox-gl
 */
export const createClusterElement: CreateClusterElement = ({
  className,
  clusterCount,
  size
}) => {
  const node = document.createElement('div');
  node.className = className ?? 'b-goBalMap__cluster';
  node.innerHTML = `${clusterCount}`;
  node.style.width = `${size}px`;
  node.style.height = `${size}px`;
  return node;
};

const createBasicMarkerSvg = () => {
  return `<svg viewBox="0 0 57 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="56" height="56" rx="28" transform="matrix(1 0 0 -1 0.181641 56)" />
    <path d="M28.1816 16C23.746 16 20.1816 19.6744 20.1816 24.2503C20.1816 28.8247 28.1816 40 28.1816 40C28.1816 40 36.1816 28.8247 36.1816 24.2503C36.1816 19.6744 32.6173 16 28.1816 16ZM28.1816 27.9993C26.1459 27.9993 24.545 26.3498 24.545 24.2489C24.545 22.1494 26.1445 20.4985 28.1816 20.4985C30.2174 20.4985 31.8183 22.148 31.8183 24.2489C31.8183 26.3498 30.2175 27.9993 28.1816 27.9993Z"/>
  </svg>`;
};

/**
 * Create an NWO marker for mapbox-gl
 */
export const createNwoMarkerElement: CreateNwoMarkerElement = ({
  className,
  entry
}) => {
  // Create marker button with class
  const markerEl = document.createElement('button');
  markerEl.className = className ?? 'b-nwoPropertyMap__marker';

  // Add data to marker
  markerEl.setAttribute('data-entry', JSON.stringify(entry));

  // Add svg as innerHTML
  const svgString = createBasicMarkerSvg();
  markerEl.innerHTML = svgString;

  return markerEl;
};

/**
 * Create a marker for mapbox-gl
 */
export const createGoBalMarkerElement: CreateGoBalMarkerElement = () => {
  const elString = createBasicMarkerSvg();
  const markerEL = document.createElement('div');
  markerEL.className = 'b-goBalMap__marker';
  markerEL.innerHTML = elString;

  return markerEL;
};

/**
 * Create Popup element for mapbox-gl
 */
export const createPopupElement: CreatePopupElement = ({ entry }) => {
  const isProperty = entry.entryType && entry.entryType === 'property';
  let elString = '';

  /**
   * Properties|Buildings are the only types to have squareFeet and status
   * - #TODO: look to see if PropertyCard.ts and BasicCard.ts are interchangeable
   */
  if (isProperty) {
    elString = `
      <img src="${entry.image}" alt="Preview image for ${
      entry.title
    }" data-img />
      <div class="b-goBalMap__popup-content u-p-8 u-font-primary">
          <a href="${entry.url}" class="u-outline-none | u-group">
              <p
                data-title
                class="u-text-primary-800 u-text-[1.375rem] u-leading-[1.45] u-font-medium u-transition-colors u-duration-300 | group-hover:u-text-primary-600 group-focus:u-text-primary-600"
              >
                ${entry.title}
              <p>
          </a>
          ${
            entry.address || entry.availableSpace || entry.status
              ? `<div class="u-text-base u-leading-6 u-mt-3">
                  ${
                    entry.address
                      ? `<address data-address>${entry.address}</address>`
                      : ''
                  }

                  ${
                    entry.availableSpace || entry.status
                      ? `<div class="u-flex u-items-center u-space-x-3 u-mt-3">
                          <p>${entry.availableSpaceFormatted} SF</p>
                          <p>${entry.status}</p>
                      </div>`
                      : ''
                  }
              </div>`
              : ''
          }
          ${
            entry.mapCtaUrl &&
            `
          <a
            data-action
            href="${entry.mapCtaUrl}"
            aria-label="Go to the property page for ${entry.title}."
            target="_blank"
              class="
                u-flex u-items-center u-text-white u-mt-4 u-py-3 u-px-7 u-rounded-full u-bg-primary-900 | u-group u-max-w-fit
              "
              >
                <span>${entry.mapCtaText}</span>
                  <i class="b-icon | u-ml-4 u-flex u-items-center u-justify-center">
                    <svg width="12" height="9" viewBox="0 0 12 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.2598 4.86121L6.9945 8.88257L5.581 7.53822L7.52767 5.78471H0V3.93769H7.52767L5.581 2.18419L6.9945 0.839844L11.2598 4.86121Z" />
                    </svg>
                  </i>
          </a>`
          }
      </div>`;
  } else {
    elString = `
      <img src="${entry.image}" alt="Preview image for ${entry.title}" />
      <div class="b-goBalMap__popup-content u-p-8 u-font-primary">
       ${
         entry.url
           ? `<a href="${entry.url}" class="u-outline-none | u-group">`
           : ''
       }
            <p
              data-title
              class="u-text-xl u-text-primary-800 u-text-[1.375rem] u-leading-[1.45] u-font-medium u-transition-colors u-duration-300 | hover:u-text-primary-600 focus:u-text-primary-600"
            >${entry.title}<p>
          ${entry.url ? '</a>' : ''}
          ${
            entry.address
              ? `<div class="u-text-base u-leading-6 u-mt-3">
                    <address>${entry.address}</address>
                </div>`
              : ''
          }
      </div>`;
  }

  const popupEl = document.createElement('div');
  popupEl.innerHTML = elString;

  return popupEl;
};

/**
 * Create Popup element for mapbox-gl
 */
export const createNWOPopupElement: CreateNWOPopupElement = ({ entry }) => {
  const elString = `
    <article class="u-w-[32.8125rem] u-max-w-full">
        <header
            data-header
            class="u-relative u-pt-20 u-pb-10 u-text-grayscale-100 u-px-7 | md:u-px-14"
        >
            <img
                src="${entry.image}"
                alt="Image of ${entry.title}"
                class="u-w-full u-h-full u-object-cover u-z-[0] u-absolute u-top-0 u-left-0"
                data-image
            >

            <div class="c-flow c-flow--2.5 | u-relative u-z-[2]">
                <h2
                    class="
                        b-eyebrow |
                        u-text-northwood-overline u-font-secondary u-uppercase u-transition-colors u-duration-700 u-text-grayscale-100
                    "
                >
                    ${entry.eyebrow}
                </h2>

                <div class="u-text-northwood-h3" data-title>${entry.title}</div>
            </div>
        </header>

        <section
            data-content
            class="c-flow c-flow--11 | u-bg-grayscale-100 u-py-11 u-px-7 | md:u-px-14"
        >
            <div>
                <h2
                    data-address
                    class="
                        b-eyebrow |
                        u-text-northwood-overline u-font-secondary u-uppercase u-transition-colors u-duration-700 u-text-primary-800 u-leading-[1.2]
                    "
                >
                    ${entry.address}
                </h2>
            </div>

            <div class="u-flex u-flex-wrap u-justify-between u-items-center u-gap-y-4">
                <a
                    data-action
                    href="${entry.url}"
                    aria-label="Go to the property page for ${entry.title}."
                    target="${
                      entry.targetString ? entry.targetString : '_blank'
                    }"
                    class="
                        b-action b-button b-button--color-teal b-button--style-primary |
                        u-flex u-items-center u-justify-center u-text-base u-mr-4 | sm:u-text-sm
                    "
                >
                    <span>View Property</span>

                    <i class="b-icon | u-ml-4 u-flex u-items-center u-justify-center">
                      <svg width="12" height="9" viewBox="0 0 12 9" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.2598 4.86121L6.9945 8.88257L5.581 7.53822L7.52767 5.78471H0V3.93769H7.52767L5.581 2.18419L6.9945 0.839844L11.2598 4.86121Z" />
                      </svg>
                    </i>
                </a>

                ${
                  entry.squareFeet && entry.squareFeet !== '0'
                    ? `
                      <p
                          class="u-text-northwood-h4 u-text-secondary-800 u-whitespace-nowrap"
                      >
                          ${entry.squareFeet} SF Available
                      </p>
                    `
                    : ''
                }
            </div>
        </section>
    </article>
  `;

  const popupEl = document.createElement('div');
  popupEl.innerHTML = elString;

  return popupEl;
};
