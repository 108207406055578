/**
 * NwoDetailCard that matches our northwoodOfficeDetailCard.twig template
 */
export const NwoDetailCard = (item: any, { html }: { html: any }) => {
  const { cardImage, date, eyebrow, title, url } = item;

  return html`
    <article class="b-detailCard">
      <a href="${url}" class="c-flow c-flow--4 | u-relative u-group">
        <div
          class="b-detailCard__imageContainer | u-flex u-relative u-overflow-hidden after:u-bg-grayscale-100"
        >
          <img
            src="${cardImage}"
            class="u-block u-w-full u-object-cover u-z-[1]"
          />
        </div>

        <div
          class="c-flow c-flow--2 | u-relative u-z-[2] u-pr-10 u-delay-300 | group-hover:-u-translate-y-[2rem] group-focus:-u-translate-y-[2rem]"
        >
          ${eyebrow && date
            ? html`
                <div
                  class="u-flex u-row u-flex-wrap u-relative u-items-center u-gap-x-6 u-gap-y-2"
                >
                  <span
                    class="u-whitespace-nowrap u-flex u-items-center u-gap-x-6"
                  >
                    <div
                      class="u-text-4 u-font-bold u-text-secondary-800 u-uppercase u-whitespace-normal"
                    >
                      ${eyebrow}
                    </div>

                    <div
                      class="u-relative u-bg-grayscale-300 u-w-[10px] u-h-[10px] u-mx u-rounded-[50%] u-flex-shrink-0 u-whitespace-normal"
                    ></div>
                  </span>

                  <p class="u-text-primary-300 u-font-bold u-uppercase">
                    ${date}
                  </p>
                </div>
              `
            : ''}

          <p class="u-font-bold u-text-[1.375rem] u-leading-[1.45]">${title}</p>

          <div
            class="u-absolute u-top-1/2 u-right-0 -u-translate-y-1/2 -u-translate-x-[1rem] u-bg-secondary-800 u-w-9 u-h-9 u-rounded-[3.75rem] u-flex u-content-center u-items-center u-justify-center u-bg-nwoCarouselBg u-opacity-0 | group-hover:u-opacity-100 group-focus:u-opacity-100 group-hover:u-translate-x-0 group-focus:u-translate-x-0"
          >
            <i class="b-icon | u-text-grayscale-100">
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2598 4.86121L6.9945 8.88257L5.581 7.53822L7.52767 5.78471H0V3.93769H7.52767L5.581 2.18419L6.9945 0.839844L11.2598 4.86121Z"
                />
              </svg>
            </i>
          </div>
        </div>
      </a>
    </article>
  `;
};

export default NwoDetailCard;
