import { connectGeoSearch } from 'instantsearch.js/es/connectors';
import mapboxgl from 'mapbox-gl';
import { Property } from '../../../types';
import {
  createGoBalMarkerElement,
  createNwoMarkerElement,
  createPopupElement
} from '../../../utils/mapUtils';
import { createMap } from '../../GoBalMapModule';

interface IRenderGeoSearchOptions {
  items: any;
  widgetParams: any;
}

let map: any = null;
let markers: any = [];

// Define the structure of your hits
interface GeoHit {
  id: string;
  name: string;
  _geo: {
    lat: number;
    lng: number;
  };
}
interface SearchConfig {
  index: string;
  input: {
    placeholder: string;
  };
  filters?: {
    [key: string]: string[];
  };
  facets: Array<{
    type: string;
    facet: string;
    options: string[];
    selector: string;
  }>;
  sortOptions: Array<{
    label: string;
    value: string;
  }>;
}

async function fetchAllHits(indexUid: string): Promise<GeoHit[]> {
  let hits: GeoHit[] = [];
  const limit = 50;
  const headers: Record<string, string> = {
    'Content-Type': 'application/json'
  };
  const isRestaurant = window.location.pathname.includes('restaurant');

  // Conditionally add the Authorization header if MEILI_API_KEY is present
  if (process.env.MEILI_API_KEY) {
    headers.Authorization = `Bearer ${process.env.MEILI_API_KEY}`;
  }

  const response = await fetch(
    `${process.env.MEILI_FRONTEND_HOST}/multi-search`,
    {
      method: 'POST',
      headers,
      body: JSON.stringify({
        queries: [
          {
            indexUid,
            limit,
            ...(isRestaurant && { facets: ['category'] }), // Specify the facets you want
            ...(isRestaurant && { filter: ["category = 'Restaurants'"] })
          }
        ]
      })
    }
  ).then((res) => res.json());

  const responseHits = response.results[0].hits;

  // Accumulate hits
  hits = [...hits, ...responseHits];

  return hits;
}

const renderGeoSearch = (
  renderOptions: IRenderGeoSearchOptions,
  isFirstRender: boolean
) => {
  const { items, widgetParams } = renderOptions;
  const { container } = widgetParams;
  const isNorthwoodSite = window.location.hostname.includes('northwoodoffice');
  let hits: any[] = [];

  const searchListingContainer: HTMLElement = document.querySelector(
    '[data-instant-search-listing]'
  );

  if (!searchListingContainer) {
    return;
  }

  // Query the search config
  const searchConfig: SearchConfig = JSON.parse(
    searchListingContainer.dataset.instantSearchListing
  );

  if (isFirstRender && process.env.MAPBOX_API_KEY) {
    map = createMap(container, {
      accessToken: process.env.MAPBOX_API_KEY,
      dragPan: false,
      center: {
        lat: 35.0469,
        lng: -80.8455
      },
      style: isNorthwoodSite
        ? 'mapbox://styles/northwoodoffice/clrpb9v4z006y01pf86d5459b'
        : 'mapbox://styles/northwoodoffice/clrpb9v4z006y01pf86d5459b',
      zoom: 14
    });
  }

  // Define a function to render hits once we have them
  function renderAllHits(allHits: any) {
    hits = allHits.length > 0 ? allHits : items;

    // Remove existing markers
    markers.forEach((marker: any) => marker.remove());

    // Loop and add each marker/popup
    markers = hits.map((data: Property) => {
      const { lat, lng } = data._geo;

      if (!lat || !lng) {
        return null;
      }

      const markerEl = isNorthwoodSite
        ? createNwoMarkerElement({ className: 'b-nwoPropertyMap__marker' })
        : createGoBalMarkerElement();
      const popupEl = createPopupElement({
        entry: data as Property
      });
      const popup = new mapboxgl.Popup({
        closeButton: false,
        /** on ipad or greater, stick to design. otherwise adjust for screensize */
        anchor: window.innerWidth >= 768 ? 'left' : 'bottom',
        offset: window.innerWidth >= 768 ? [40, 0] : [0, -20],
        className: 'b-goBalMap__popup',
        focusAfterOpen: false
      })
        .setHTML(popupEl.innerHTML)
        .setMaxWidth('330px')
        .setLngLat([lng, lat])
        .on('open', () => {
          markerEl.classList.add('is-active');
          map.flyTo({
            center: [lng, lat],
            duration: 1000
          });

          /**
           * GTM Tracking
           */
          const popupTitle = (popup as any)._content.querySelector(
            '[data-title]'
          )?.innerText;
          const popupAddress = (popup as any)._content.querySelector('address')
            ?.innerText;

          if (popupTitle && popupAddress) {
            window.dataLayer.push({
              event: 'mapClicked',
              popupTitle,
              popupAddress,
              pageURL: window.location.href,
              userId: '',
              sessionId: sessionStorage.sessionId,
              hitTimeStamp: getGtmUtcString(new Date())
            });
          } else if (popupTitle && !popupAddress) {
            window.dataLayer.push({
              event: 'mapClicked',
              popupTitle,
              popupAddress: 'No Address for popup',
              pageURL: window.location.href,
              userId: '',
              sessionId: sessionStorage.sessionId,
              hitTimeStamp: getGtmUtcString(new Date())
            });
          }
        })
        .on('close', () => {
          markerEl.classList.remove('is-active');
        });

      return new mapboxgl.Marker(markerEl)
        .setLngLat([lng, lat])
        .setPopup(popup)
        .addTo(map);
    });

    if (markers.length) {
      const markerBounds = new mapboxgl.LngLatBounds();
      markers.forEach((marker: any) => {
        let coords;
        try {
          coords = new mapboxgl.LngLat(marker._lngLat.lng, marker._lngLat.lat);
        } catch (e) {
          coords = null;
        }
        if (coords) {
          markerBounds.extend(coords);
        }
      });
      map.fitBounds(markerBounds, {
        padding: 100,
        maxZoom: 14
      });
    }
  }

  // Fetch all hits and then render them
  fetchAllHits(searchConfig.index).then((allHits) => {
    renderAllHits(allHits);
  });
};

const mapboxGeoSearch = connectGeoSearch(renderGeoSearch);

export default mapboxGeoSearch;
