/**
 * Imports
 */

/**
 * Juicer Class
 */
class Juicer {
  /**
   * ID of the Juicer feed to pull from and render
   */
  feedID: string;

  /**
   * Array of our feed items markup
   */
  feedItems: HTMLElement[];

  /**
   * Array of feed results from Juicer
   */
  feedResults: any[];

  /**
   * Grid container for our feed items
   */
  feedGrid: HTMLElement;

  /**
   * Parent <section> element of the module
   */
  parentEl: HTMLElement;

  /**
   * Constructor
   */
  constructor(parentEl: HTMLElement) {
    this.parentEl = parentEl;
    this.feedGrid = this.parentEl.querySelector('[data-grid]');
    this.feedID = this.parentEl.dataset.juicer ?? 'ballantynecampus';
    this.feedItems = Array.from(
      this.parentEl.querySelectorAll('[data-grid-item]')
    );
  }

  /**
   * Fetches Juicer Feed items
   *
   * @return    {undefined} returns nothing, undefined
   */
  async fetchFeedItems() {
    const response = await fetch(
      `https://www.juicer.io/api/feeds/${this.feedID}?per=10&page=1`
    );
    const data = await response.json();
    const { items } = data.posts;
    if (items.length) {
      const imagesOnly = items.filter((item: any) => item.image !== null);
      this.feedResults = imagesOnly;
    }
  }

  /**
   * Renders feed items
   *
   * @return    {undefined} returns nothing, undefined
   */
  renderFeedItems() {
    this.feedResults.slice(0, 4).forEach((result, i) => {
      const targetGridEl = this.feedGrid.querySelector(
        `[data-grid-item="${i + 1}"]`
      );
      const iconContainer = targetGridEl.querySelector(
        '.b-socialFeed__grid__item__icon'
      );
      const imgEl = targetGridEl.querySelector('img');
      const link = targetGridEl.querySelector('a');

      // Set anchor attributes
      link.setAttribute('href', result.full_url);
      link.title =
        result.unformatted_message?.replace(/<br \/>|<br>/g, ' ') ?? ''; // remove <br /> or <br> tags
      // Set image attributes
      if (result.image) {
        imgEl.src = result.image;
        imgEl.setAttribute(
          'alt',
          result.unformatted_message ?? `Social Post Image ${i}`
        );
        link.setAttribute('data-social-image-url', result.image);
        imgEl.classList.remove('u-hidden');
        link.setAttribute('data-platform', result.source.source.toLowerCase());
      }

      // Set icon attributes
      if (iconContainer && result.source.source) {
        const iconImg = iconContainer.querySelector('img');
        iconImg.src = `/dist/img/icons/${result.source.source.toLowerCase()}.svg`;
        iconImg.alt = `${result.source.source} icon for social platform.`;
      }
    });
  }

  /**
   * Initialization : Attach listeners and any other methods that need to run on init
   *
   * @return    {[type]}          [return description]
   */
  async init() {
    await this.fetchFeedItems();

    if (!this.feedResults.length) return;

    this.renderFeedItems();

    this.feedGrid.classList.remove('isLoading');
  }
}

/**
 * Initialization, query element(s) if needed and create & init new class(es)
 *
 * @return    {undefined}          [returns nothing, initializes module]
 */
export default function initJuicer(): void {
  const juicerSections = Array.from(document.querySelectorAll('[data-juicer]'));

  juicerSections.forEach((section: HTMLElement) => {
    const juicerModule = new Juicer(section);
    juicerModule.init();
  });
}
