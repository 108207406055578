/**
 * Method for handling all things GTM Tracking
 */
const initNwoGTMTracking = (): void => {
  /**
   * General CMS Actions across the site
   */
  const allActions = document.querySelectorAll('[data-action]');
  if (allActions.length) {
    allActions.forEach((action) => {
      action.addEventListener('click', (e) => {
        const anchor = e.currentTarget as HTMLAnchorElement;
        const color = Array.from(anchor.classList)
          .filter((className) => className.includes('color-'))[0]
          .split('color-')[1];
        const style = Array.from(anchor.classList)
          .filter((className) => className.includes('style-'))[0]
          .split('style-')[1];

        window.dataLayer.push({
          event: 'buttonClicks',
          buttonColor: color,
          buttonStyle: style,
          buttonText: anchor.innerText.trim() ?? 'N/A',
          pageURL: window.location.href,
          userId: '',
          sessionId: sessionStorage.sessionId,
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    });
  }

  /**
   * Primary Nav clicks
   */
  const navigationLinks = document.querySelectorAll('[data-header-link]');
  if (navigationLinks) {
    navigationLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        const anchor = e.currentTarget as HTMLAnchorElement;

        window.dataLayer.push({
          event: 'navClicks',
          hitTimeStamp: getGtmUtcString(new Date()),
          mainNav: anchor.innerText,
          sessionId: sessionStorage.sessionId,
          userId: ''
        });
      });
    });
  }

  /**
   * Header/Footer Logo Clicks
   */
  const headerLogos = document.querySelectorAll('[data-header-logo]');
  const footerLogo = document.querySelector('[data-footer-logo]');
  if (headerLogos) {
    headerLogos.forEach((logo) => {
      logo.addEventListener('click', () => {
        window.dataLayer.push({
          event: 'headerLogoClicks',
          hitTimeStamp: getGtmUtcString(new Date()),
          sessionId: sessionStorage.sessionId,
          userId: ''
        });
      });
    });
  }
  if (footerLogo) {
    footerLogo.addEventListener('click', () => {
      window.dataLayer.push({
        event: 'footerLogoClicks',
        hitTimeStamp: getGtmUtcString(new Date()),
        sessionId: sessionStorage.sessionId,
        userId: ''
      });
    });
  }

  /**
   * Footer Nav clicks
   */
  const footerNavLinks = document.querySelectorAll('[data-footernav-link]');
  if (footerNavLinks) {
    footerNavLinks.forEach((link) => {
      link.addEventListener('click', (e) => {
        const anchor = e.currentTarget as HTMLAnchorElement;

        window.dataLayer.push({
          event: 'footerClick',
          footerNav: anchor.innerText ?? 'N/A',
          userId: '',
          sessionId: sessionStorage.sessionId,
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    });
  }

  /**
   * Market carousel and grid cards
   */
  const marketModules = document.querySelectorAll(
    '[data-content-type="markets"]'
  );
  if (marketModules) {
    marketModules.forEach((carousel) => {
      const carouselCardsAnchors = carousel.querySelectorAll('article > a');
      carouselCardsAnchors.forEach((anchor) => {
        anchor.addEventListener('click', (e) => {
          const clickedAnchor = e.currentTarget as HTMLElement;
          const marketTitle =
            clickedAnchor.querySelector('[data-title]')?.textContent?.trim() ??
            'N/A';

          window.dataLayer.push({
            event: 'marketName',
            marketName: marketTitle,
            hitTimeStamp: getGtmUtcString(new Date()),
            sessionId: sessionStorage.sessionId,
            userId: ''
          });
        });
      });
    });
  }

  /**
   * News Carousels and Listing Callouts
   */
  const pushNewsData = (articleDate: string, articleTitle: string) => {
    window.dataLayer.push({
      event: 'newsArticleClicks',
      articleClicked: articleTitle,
      dateOfArticle: articleDate,
      hitTimeStamp: getGtmUtcString(new Date()),
      sessionId: sessionStorage.sessionId,
      userId: ''
    });
  };
  const newsCarouselsAndGrids = document.querySelectorAll(
    '[data-content-type="northwoodOfficeNews"]'
  );
  if (newsCarouselsAndGrids) {
    newsCarouselsAndGrids.forEach((module: any) => {
      const moduleCardAnchors = module.querySelectorAll('article > a');
      moduleCardAnchors.forEach((anchor: HTMLAnchorElement) => {
        anchor.addEventListener('click', (e) => {
          const clickedArticle = e.currentTarget as HTMLElement;
          const articleTitle =
            clickedArticle.querySelector('[data-title]')?.textContent?.trim() ??
            '';
          const articleDate =
            clickedArticle.querySelector('[data-date]')?.textContent?.trim() ??
            'N/A';
          pushNewsData(articleDate, articleTitle);
        });
      });
    });
  }
  const newsListingCards = document.querySelectorAll('[data-news-card]');
  if (newsListingCards) {
    newsListingCards.forEach((card: any) => {
      const cardAnchor: HTMLAnchorElement = card.querySelector('a');
      cardAnchor.addEventListener('click', () => {
        const articleTitle =
          card.querySelector('[data-title]')?.textContent?.trim() ?? '';
        const articleDate =
          card.querySelector('[data-date]')?.textContent?.trim() ?? 'N/A';
        pushNewsData(articleDate, articleTitle);
      });
    });
  }

  /**
   * Related Properties Carousel Cards
   */
  const propertiesCarousels = document.querySelectorAll(
    '.b-cardCarousel[data-content-type="portfolios"]'
  );
  const relatedPropertiesCarousels = Array.from(
    propertiesCarousels
  ).filter((carousel) =>
    (carousel.querySelector('.b-eyebrow') as HTMLElement).innerText
      .toLowerCase()
      .includes('related')
  );
  if (relatedPropertiesCarousels) {
    relatedPropertiesCarousels.forEach((carousel) => {
      const relatedPropertiesAnchors = carousel.querySelectorAll('article > a');
      relatedPropertiesAnchors.forEach((anchor) => {
        anchor.addEventListener('click', (e) => {
          const cardTitle = ((e.currentTarget as HTMLElement).querySelector(
            '[data-title]'
          ) as HTMLElement)?.innerText.trim();

          window.dataLayer.push({
            event: 'relatedPropertyClicks',
            hitTimeStamp: getGtmUtcString(new Date()),
            propertyName: cardTitle ?? 'N/A',
            sessionId: sessionStorage.sessionId,
            userId: ''
          });
        });
      });
    });
  }

  /**
   * Email Link Clicks
   */
  const links = document.querySelectorAll('a');
  links.forEach((link) => {
    if (link.href.indexOf('mailto:') > -1) {
      link.addEventListener('click', () => {
        let type = 'general';
        if (link.href.indexOf('leasing') > -1) {
          type = 'leasing';
        }
        window.dataLayer.push({
          event: 'emailLinkClicks',
          emailClickType: type,
          pageUrl: window.location.href,
          userId: '',
          sessionId: sessionStorage.sessionId,
          hitTimeStamp: getGtmUtcString(new Date())
        });
      });
    }
  });

  /**
   * Contact Form submission
   */
  const newsletterSignupForm = document.querySelector('#nwoContactForm');
  if (newsletterSignupForm) {
    newsletterSignupForm.addEventListener('submit', () => {
      const firstNameInput = newsletterSignupForm.querySelector(
        'input[name="firstName"]'
      ) as HTMLInputElement;
      const lastNameInput = newsletterSignupForm.querySelector(
        'input[name="lastName"]'
      ) as HTMLInputElement;
      const emailInput = newsletterSignupForm.querySelector(
        'input[name="email"]'
      ) as HTMLInputElement;

      window.dataLayer.push({
        clientId: '',
        event: 'contactFormSubmission',
        firstName: firstNameInput.value,
        hitTimeStamp: getGtmUtcString(new Date()),
        lastName: lastNameInput.value,
        pageUrl: window.location.href,
        sessionId: sessionStorage.sessionId,
        userEmail: emailInput.value,
        userEmailHashed: '',
        userId: ''
      });
    });
  }

  return null;
};

export default initNwoGTMTracking;
