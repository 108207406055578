// TODO: Abstract to Parent-level class that gets extended by northwood/gobal (and any future site)

/**
 * Imports
 */
import { debounce } from 'lodash';
import * as focusTrap from 'focus-trap';

/**
 * Header Class
 */
export default class Header {
  /**
   * Document Body
   */
  body: HTMLBodyElement | any;

  /**
   * Focus Trap instance
   */
  focusTrap: any = null;

  /**
   * Parent <header> element
   */
  header: HTMLElement = null;

  /**
   * Logo within the header
   */
  logo: HTMLElement = null;

  /**
   * Trigger for opening mobile nav
   */
  mobileTrigger: HTMLButtonElement;

  /**
   * All Nav Links
   */
  navLinks: HTMLAnchorElement[];

  /**
   * Nav Links that are inside a subnav
   */
  navLinksInSubnav: HTMLAnchorElement[];

  /**
   * Any top-level Nav Links with children/subnav
   */
  navLinksWithChildren: HTMLAnchorElement[];

  /**
   * Breakpoint at which we adjust responsiveness and styles
   */
  headerBreakpoint: number = 1120; // 1,120 = "lg" breakpoint (70em)

  /**
   * Constructor
   *
   * @param     {HTMLElement}    headerEl
   *
   * @return    {void}
   */
  constructor(headerEl: HTMLElement) {
    this.body = document.body;
    this.header = headerEl;
    this.logo = this.header.querySelector('[data-header-logo]');
    this.mobileTrigger = this.header.querySelector('[data-mobile-trigger]');
    this.navLinks = Array.from(
      this.header.querySelectorAll('[data-header-link]')
    );
    this.navLinksInSubnav = Array.from(
      this.header.querySelectorAll('[data-header-link="subnav"]')
    );
    this.navLinksWithChildren = this.navLinks.filter(
      (link) => link.getAttribute('data-has-children') === 'true'
    );
  }

  /**
   * Handle a click on a nav item with children.
   * If desktop: ignore.
   * If mobile: preventDefault, open subnav and adjust tabIndexing
   *
   * @return    {undefined}          returns nothing, undefined
   */
  handleLinkWithChildrenClick(e: any): undefined {
    const isMobileMenu = window.innerWidth < this.headerBreakpoint;
    // const targetLinkParent = e.target.parentNode;
    const targetLinkParent = e.currentTarget.parentNode;
    const childLinks = targetLinkParent.querySelectorAll(
      '[data-header-link="subnav"]'
    );

    if (isMobileMenu) {
      e.preventDefault();
      targetLinkParent.classList.toggle('active');

      if (targetLinkParent.classList.contains('active')) {
        childLinks.forEach((link: any) => {
          link.tabIndex = '0'; // eslint-disable-line no-param-reassign
        });
      } else {
        childLinks.forEach((link: any) => {
          link.tabIndex = '-1'; // eslint-disable-line no-param-reassign
        });
      }
    } else {
      return undefined;
    }

    return undefined;
  }

  /**
   * Handle the mobile trigger (hamburger) being clicked to open/close nav
   *
   * @return    {undefined}          returns nothing, undefined
   */
  handleMobileTriggerClick() {
    this.body.classList.toggle('b-body--locked');
    this.header.classList.toggle('b-header--mobileNavActive');
    this.handleResponsiveTabIndexing();
    this.handleResponsiveUX();

    if (this.header.classList.contains('b-header--mobileNavActive')) {
      this.focusTrap.activate();
    } else {
      this.focusTrap.deactivate();
    }
  }

  /**
   * Handles Responsive TabIndexing by disabling the links when mobile and not active
   *
   * @return    {undefined}                    returns nothing, undefined
   */
  handleResponsiveTabIndexing(): undefined {
    const isMobileMenu = window.innerWidth < this.headerBreakpoint;
    const isMobileMenuActive = this.header.classList.contains(
      'b-header--mobileNavActive'
    );

    if (isMobileMenu && !isMobileMenuActive) {
      this.navLinks.forEach((link) => {
        link.tabIndex = -1; // eslint-disable-line no-param-reassign
      });

      return undefined;
    }

    // For simple navs, no dropdown. commenting out for now
    // this.navLinks.forEach((link) => {
    //   link.tabIndex = 0; // eslint-disable-line no-param-reassign
    // });
    this.navLinks.forEach((link) => {
      // Don't adjust subnav links on mobile, we handle those a little differently
      if (!this.navLinksInSubnav.includes(link) || !isMobileMenu) {
        link.tabIndex = 0; // eslint-disable-line no-param-reassign
      }
    });

    return undefined;
  }

  /**
   * Resets things such as active states whenever a user changes from small to large viewport or vice-versa
   *
   * @return    {undefined}          returns nothing, undefined
   */
  handleResponsiveUX(): undefined {
    const isDesktopHeader = window.innerWidth > this.headerBreakpoint;

    if (isDesktopHeader) {
      this.body.classList.remove('b-body--locked');
      this.header.classList.remove('b-header--mobileNavActive');
    }

    // Remove active from all things in the header
    const activeItems = this.header.querySelectorAll('.active');
    activeItems.forEach((item) => {
      item.classList.remove('active');
    });

    return undefined;
  }

  /**
   * Method for attaching our event listeners
   */
  attachListeners() {
    // Basic hamburger menu click
    this.mobileTrigger.addEventListener('click', () =>
      this.handleMobileTriggerClick()
    );

    // Handle links with subnavs
    this.navLinksWithChildren.forEach((link) => {
      link.addEventListener('click', (e) => {
        // eslint-disable-next-line no-console
        this.handleLinkWithChildrenClick(e);
      });
    });

    // Window Events
    window.addEventListener(
      'resize',
      debounce(() => this.handleResponsiveTabIndexing())
    );
    window.addEventListener(
      'resize',
      debounce(() => this.handleResponsiveUX())
    );

    // Setup our focus trap
    this.focusTrap = focusTrap.createFocusTrap(this.header, {
      escapeDeactivates: () => {
        this.handleMobileTriggerClick();

        return true;
      }
    });
  }

  async init() {
    // Remove NoJS Data Attr to signal that JS is enabled
    this.header.removeAttribute('data-js-disabled');

    // Attach listeners and any other methods that need to run on init
    this.attachListeners();
    this.handleResponsiveTabIndexing();
  }
}
