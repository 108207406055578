/**
 * Imports
 */
import mapboxgl from 'mapbox-gl';
import Supercluster from 'supercluster';
import { NwoMarkerData, NWOPopupData, Property } from '../types';
import {
  createClusterElement,
  createNwoMarkerElement,
  createNWOPopupElement,
  flattenMapBounds,
  mapDefaults
} from '../utils/mapUtils';

export function createMap(
  container: HTMLElement,
  options: Partial<mapboxgl.MapboxOptions>
) {
  const map = new mapboxgl.Map({
    ...mapDefaults,
    ...options,
    container,
    style: 'mapbox://styles/northwoodoffice/clrpb9v4z006y01pf86d5459b'
  });

  map.addControl(
    new mapboxgl.ScaleControl({
      unit: 'imperial'
    })
  );

  map.addControl(
    new mapboxgl.NavigationControl({
      showCompass: false,
      visualizePitch: false
    }),
    'bottom-right'
  );

  // disable map zoom when using scroll
  map.scrollZoom.disable();

  return map;
}

function createPropertiesMapModule(node: HTMLElement) {
  const container = node.querySelector<HTMLElement>('[data-map]');
  const mapConfig = JSON.parse(container.getAttribute('data-map'));
  const markerData: Property[] = JSON.parse(
    node.querySelector<HTMLScriptElement>('[data-markers]').textContent
  );

  const points: GeoJSON.Feature<GeoJSON.Point>[] = markerData.map(
    (property: Property) => {
      return {
        type: 'Feature',
        properties: {
          cluster: false,
          ...property
        },
        geometry: {
          type: 'Point',
          coordinates: property.position
        }
      };
    }
  );

  const map = createMap(container, mapConfig);
  const clusterer = new Supercluster({
    radius: 60,
    maxZoom: 20
  });
  clusterer.load(points);

  map.on('load', () => {
    let markers: mapboxgl.Marker[] = [];

    function getOffset() {
      return window.innerWidth > 800 ? 300 : 0;
    }

    function renderMarkers(newData: ReturnType<typeof clusterer.getClusters>) {
      markers.forEach((marker) => marker.remove());
      markers = [];

      newData.forEach((point) => {
        const [longitude, latitude] = point.geometry.coordinates;
        const {
          cluster: isCluster,
          point_count: clusterCount
        } = point.properties;

        if (isCluster) {
          const clusterEL = createClusterElement({
            className: 'b-nwoPropertyMap__cluster',
            clusterCount,
            size: 40 + (clusterCount / points.length) * 2 * 100
          });

          clusterEL.addEventListener('click', () => {
            const expansionZoom = Math.min(
              clusterer.getClusterExpansionZoom(Number(point.id)),
              20
            );

            map.flyTo({
              center: [longitude, latitude],
              zoom: expansionZoom,
              offset: [getOffset(), 0],
              duration: 350
            });
          });

          markers.push(
            new mapboxgl.Marker(clusterEL)
              .setLngLat([longitude, latitude])
              .addTo(map)
          );
        } else {
          let popup: mapboxgl.Popup = null;
          const popupEl = createNWOPopupElement({
            entry: point.properties as NWOPopupData
          });

          const markerEl = createNwoMarkerElement({
            className: 'b-nwoPropertyMap__marker',
            entry: point.properties as NwoMarkerData
          });

          // POPUP
          popup = new mapboxgl.Popup({
            closeButton: false,
            /** on ipad or greater, stick to design. otherwise adjust for screensize */
            anchor: window.innerWidth >= 768 ? 'left' : 'bottom',
            offset: window.innerWidth >= 768 ? [40, 0] : [0, -40],
            focusAfterOpen: false
          })
            .setHTML(popupEl.innerHTML)
            .setMaxWidth('100%')
            .setLngLat([longitude, latitude])
            .on('open', () => {
              markerEl.classList.add('is-active');
              map.flyTo({
                center: [longitude, latitude],
                offset: [0, window.innerWidth >= 768 ? 0 : 200],
                duration: 1000
              });

              /**
               * GTM Tracking
               */
              const popupTitle = (popup as any)._content.querySelector(
                '[data-title]'
              ).innerText;
              const popupAddress = (popup as any)._content.querySelector(
                'address'
              ).innerText;

              if (popupTitle && popupAddress) {
                window.dataLayer.push({
                  event: 'mapClicked',
                  popupTitle,
                  popupAddress,
                  pageURL: window.location.href,
                  userId: '',
                  sessionId: sessionStorage.sessionId,
                  hitTimeStamp: getGtmUtcString(new Date())
                });
              }
            })
            .on('close', () => {
              markerEl.classList.remove('is-active');
            });

          // PUSH TO MAP
          markers.push(
            new mapboxgl.Marker(markerEl)
              .setLngLat([longitude, latitude])
              .setPopup(popup)
              .addTo(map)
          );
        }
      });
    }

    // On Zoomend
    let { zoom } = mapConfig;
    map.on('zoomend', (evt) => {
      if (map.getZoom() === zoom) return;
      zoom = evt.target.getZoom();
      const clusters = clusterer.getClusters(
        flattenMapBounds(map),
        map.getZoom()
      );
      renderMarkers(clusters);
    });
    // On Dragend
    map.on('dragend', () => {
      const clusters = clusterer.getClusters(
        flattenMapBounds(map),
        map.getZoom()
      );
      renderMarkers(clusters);
    });

    const clusters = clusterer.getClusters(
      flattenMapBounds(map),
      map.getZoom()
    );
    // Finally, render markers
    renderMarkers(clusters);
    map.flyTo({
      center: [markers[0].getLngLat().lng, markers[0].getLngLat().lat],
      duration: 350
    });
    // @ts-ignore-next-line
    markers[0]._element.classList.add('is-active');
    markers[0].getPopup().addTo(map);
  });
}

/**
 * Initialization
 *
 * @return    {undefined}          returns nothing, initializes Class
 */
export default function initModule() {
  const mapModules: any[] = Array.from(
    document.querySelectorAll('[data-property-map]')
  );

  mapModules.forEach((module) => {
    if (!module) return;

    createPropertiesMapModule(module);
  });
}
