/**
 * Imports
 */
import Header from './Header';

class HeaderNorthwood extends Header {
  async init() {
    super.init();
  }
}

export default function initHeaderNorthwood(): void {
  let header: any = document.getElementById('header');

  if (!header) {
    return null;
  }

  header = new HeaderNorthwood(header);
  header.init();

  return header;
}
