import Cookies from 'js-cookie';
import animateSequentially from '../utils/animateSequentially';

// interface INewsLetterSignUpModal {
//   animating: boolean;
//   isOpen: boolean;
//   toggleModal: () => void;
// }

// type TInitNewsLetterSignUpModal = () => INewsLetterSignUpModal;

/**
 * initNewsLetterSignUpModal
 * Initialization function for Gobal News Letter signup modal.
 * Uses the `templates/goBallantyne/_blocks/newsLetterSignupModal.twig` template
 * which is instanced conditionally inside the default template.
 *
 * @return INewsLetterSignUpModal, API with close and open functions
 */
const initNewsLetterSignUpModal = () => {
  // Get el's
  const { documentElement: doc, body } = document;
  // Footer - for hiding at bottom of page
  const footer = document.getElementById('footer');

  // Modal el's
  const shadowbox = document.getElementById('gobalNewsLetterSignupShadowBox');
  const wrapper = document.getElementById('gobalNewsLetterSignupModalWrapper');
  const modal = document.getElementById('gobalNewsLetterSignupModal');
  const closedContent = document.getElementById(
    'gobalNewsLetterSignupModalClosedContent'
  );
  const formContent = document.getElementById(
    'gobalNewsLetterSignupModalOpenContent'
  );

  if (!shadowbox || !wrapper || !modal || !closedContent || !formContent)
    return;

  const closeBtn = shadowbox.querySelector('.b-closeBtn');
  const pillActionContent = closedContent.querySelector(
    '.b-pillAction__content'
  );
  const pillIcon = closedContent.querySelector('.b-icon-ballantyne');

  if (!closeBtn || !pillActionContent || !pillIcon) return;

  let isOpen = false;
  let animating = false;

  /**
   * Modal
   * Update border-radius, toggle cursor-pointer
   */
  const modalTransition = () => {
    modal.classList.toggle('!u-rounded-none');
    modal.classList.toggle('!u-rounded-tl-[13%]');
    modal.classList.toggle('u-cursor-pointer');
  };

  /**
   * Form Content
   * Hide content when "closed"
   */
  const formContentTransition = () => {
    // Action when open
    formContent.classList.toggle('!u-max-h-[4999px]');
    formContent.classList.toggle('!u-max-w-[4999px]');
    // Active when closed
    formContent.classList.toggle('u-opacity-0');
  };

  const isMobile = () => {
    return /Mobi|Android|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(
      navigator.userAgent
    );
  };

  const setCookie = (cookie: string, value: boolean, days: number) => {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    const expires = `expires=${date.toUTCString()}`;
    document.cookie = `${cookie}=${value};${expires};path=/`;
  };

  /**
   * Position
   */
  const modalPositionTransition = () => {
    // [transition:right_100ms,bottom_100ms]
    // Move to center x
    wrapper.classList.toggle('!u-right-[50%]');
    wrapper.classList.toggle('u-translate-x-[50%]');
    // Move to center y
    wrapper.classList.toggle('!u-bottom-[50%]');
    wrapper.classList.toggle('u-translate-y-[50%]');
  };

  const toggleModal = async () => {
    if (animating) return;
    animating = true;
    isOpen = !isOpen;
    /**
     * HTML / Shadowbox transition
     * No scroll on body and add bg color for shadow-box
     */
    doc.classList.toggle('u-overflow-hidden');
    doc.classList.toggle('u-pointer-events-none');
    body.classList.toggle('b-body--locked');
    body.classList.toggle('u-pointer-events-none');
    shadowbox.classList.toggle('u-pointer-events-none');
    closedContent.classList.toggle('u-h-0');
    formContent.classList.toggle('u-pointer-events-none', !isOpen);
    wrapper.classList.toggle('!u-pointer-events-auto');
    wrapper.classList.toggle('xs-only:u-w-full');
    wrapper.classList.toggle('!u-overflow-auto');
    // Add overflow-auto to modal

    if (isOpen) {
      modal.classList.toggle('u-bg-neutrals-primary-300');
      closedContent.classList.toggle('u-translate-y-[-4rem]');
      pillActionContent.classList.toggle('u-opacity-0');
      pillIcon.classList.toggle('u-opacity-0');
      shadowbox.classList.toggle('u-bg-[rgba(0,0,0,.5)]');
      animating = await animateSequentially(
        [
          () => {
            modalTransition();
            modalPositionTransition();
          },
          () => {
            formContentTransition();
          }
        ],
        150
      );
    } else {
      animating = await animateSequentially(
        [
          () => {
            formContentTransition();
          },
          () => {
            modalPositionTransition();

            modal.classList.toggle('u-bg-neutrals-primary-300');
            closedContent.classList.toggle('u-translate-y-[-4rem]');
          },
          () => {
            modalTransition();
            pillActionContent.classList.toggle('u-opacity-0');
            pillIcon.classList.toggle('u-opacity-0');
            shadowbox.classList.toggle('u-bg-[rgba(0,0,0,.5)]');
          }
        ],
        150
      );
    }
  };

  const hideModalWhenFooterIsDisplayed = (
    entries: IntersectionObserverEntry[]
  ) => {
    entries.forEach(({ isIntersecting }) => {
      if (isIntersecting) {
        wrapper.classList.add('!u-overflow-hidden');
        modal.classList.add('!u-translate-y-[4rem]');
        modal.classList.add('!u-opacity-0');
        modal.classList.add('!u-pointer-events-none');
        closedContent.classList.add('!u-pointer-events-none');
      } else {
        wrapper.classList.remove('!u-overflow-hidden');
        modal.classList.remove('!u-translate-y-[4rem]');
        modal.classList.remove('!u-opacity-0');
        modal.classList.remove('!u-pointer-events-none');
        closedContent.classList.remove('!u-pointer-events-none');
      }
    });
  };

  const getModalHasBeenShown = (): boolean => {
    return (
      !!Cookies.get('modalPopup') || !!sessionStorage.getItem('modalDisplayed')
    );
  };

  const setModalHasBeenShown = () => {
    Cookies.set('modalPopup', 'true', { expires: 365 });
    sessionStorage.setItem('modalDisplayed', 'true');
  };

  const observer = new IntersectionObserver(hideModalWhenFooterIsDisplayed);
  observer.observe(footer);

  // Open modal
  modal.addEventListener('click', () => {
    if (isMobile) {
      const btn = document.querySelector(
        '#gobalNewsLetterSignupModalClosedContent'
      ) as HTMLElement;
      setCookie('hideNewsletterButton', true, 1);
      if (btn) {
        btn.style.display = 'none';
      }
    }
    setModalHasBeenShown();
    if (!isOpen) toggleModal();
  });

  // Close modal
  closeBtn.addEventListener('click', (e) => {
    e.stopPropagation();
    toggleModal();
  });

  /**
   * Auto-display / popup logic
   *
   * Check if modal has been displayed
   */
  if (!getModalHasBeenShown()) {
    setTimeout(() => {
      // Don't popup on homepage
      const isHomepage = window.location.pathname === '/';
      if (isHomepage) return;
      // Open modal
      toggleModal();
      // Add cookie & session storage
      setModalHasBeenShown();
    }, 1000);
  }
};

export default initNewsLetterSignUpModal;
