import { connectRefinementList } from 'instantsearch.js/es/connectors';

const gobalListMarkup = (
  isRefined: boolean,
  value: string,
  staticLabel: string
) => {
  return `
    <li>
      <label class="u-cursor-pointer">
        <input
          type="checkbox"
          value="${value}"
          ${isRefined ? 'checked' : ''}
          class="u-bg-primary-300"
        />
        <span class="u-ml-2 u-text-sm u-font-primary u-tracking-[0.0625rem] ${
          isRefined ? 'u-font-semibold' : ''
        }">${staticLabel}</span>
      </label>
    </li>
  `;
};
const northwoodListMarkup = (
  isRefined: boolean,
  value: string,
  staticLabel: string
) => {
  return `
    <li>
      <label class="u-cursor-pointer u-flex u-items-center">
        <input
          type="checkbox"
          value="${value}"
          ${isRefined ? 'checked' : ''}
          class="u-bg-nwoCarouselBg u-h-6 u-w-6"
        />
        <span class="u-ml-3 u-text-lg u-font-primary u-text-primary-900 u-tracking-[0.0625rem] u-transition-all u-duration-300 | hover:u-text-secondary-800 | ${
          isRefined ? 'u-font-semibold u-text-secondary-800 !u-ml-4' : ''
        }">${staticLabel}</span>
      </label>
    </li>
  `;
};

/**
 * Static RefinementList
 * - prevents facets from disappearing if not selected
 */
const staticRefinementList: any = (idSelector: string) =>
  connectRefinementList((renderOptions, isFirstRender) => {
    const { items, refine, widgetParams } = renderOptions;
    const container = document.getElementById(idSelector);
    const isNorthwoodSite = window.location.hostname.includes(
      'northwoodoffice'
    );

    if (isFirstRender) {
      container.addEventListener('click', ({ target }: any) => {
        const input = target.closest('input');

        if (input) {
          refine(input.value);

          // GTM Tracking for all selected filters
          const allSelectedFilters = Array.from(
            container.closest('aside').querySelectorAll('input')
          )
            .filter((filter) => filter.checked === true)
            .map((filter) => filter.value);

          if (!allSelectedFilters.length) {
            return;
          }

          // GTM Tracking
          window.dataLayer.push({
            event: 'browseTypeFilterSelection',
            typeSelected: allSelectedFilters.join(', '),
            userId: '',
            pageURL: window.location.href,
            sessionId: sessionStorage.sessionId,
            hitTimeStamp: getGtmUtcString(new Date())
          });
        }
      });

      return;
    }

    /* @ts-ignore-next-line */
    const list = widgetParams.items.map(
      ({ label: staticLabel, value }: any) => {
        const { isRefined } = items.find(
          ({ label }) => label === staticLabel
        ) || {
          isRefined: false
        };

        if (isNorthwoodSite) {
          return northwoodListMarkup(isRefined, value, staticLabel);
        }

        return gobalListMarkup(isRefined, value, staticLabel);
      }
    );

    container.innerHTML = `
      <ul class="c-flow c-flow--5">
        ${list.join('')}
      </ul>
    `;
  });

export default staticRefinementList;
