/**
 * NwoBasicCard that only expects an image, title and URL
 */
export const NwoBasicCard = (item: any, { html }: { html: any }) => {
  const { cardPreviewColor, city, image, state, title, url } = item;
  const cardColor = cardPreviewColor ?? 'teal';

  // All or nothin' baby
  if (!city || !image || !state || !title || !url) {
    return null;
  }

  return html`
    <article
      class="b-listingCalloutCard b-listingCalloutCard--${cardColor} | u-flex u-flex-wrap"
    >
      <a
        href="${url}"
        target="${item.target ?? '_self'}"
        class="hit | u-w-full u-flex u-flex-col u-gap-12 u-group u-text-primary-800 | sm:u-flex-row sm:u-items-center"
        data-type="basicCard"
      >
        <div
          class="b-calloutCardThumbnail b-calloutCardThumbnail--${cardColor} | u-relative u-flex-shrink-0 | sm:u-mr-8"
        >
          <img
            alt="Thumbnail for ${title}"
            src="${image}"
            class="u-w-full u-h-[15.625rem] u-relative u-z-[2] u-object-cover u-transition-all u-duration-300 | sm:u-w-[15.625rem] | group-hover:-u-translate-x-[7%] group-hover:-u-translate-y-[7%] group-focus:-u-translate-x-[7%] group-focus:-u-translate-y-[7%]"
          />

          <div
            class="b-calloutCardThumbnail__hoverBg | u-absolute u-bottom-0 u-right-0 u-z-[1] u-w-[93%] u-h-[93%]"
          ></div>

          <div
            class="b-calloutCardThumbnail__arrow | u-w-9 u-h-9 u-bg-grayscale-100 u-absolute u-top-1/2 u-left-1/2 -u-translate-x-1/2 -u-translate-y-1/2 u-z-[3] u-flex u-content-center u-items-center u-justify-center u-rounded-full u-scale-[2] u-opacity-0 | group-hover:u-opacity-100 group-focus:u-opacity-100 group-hover:u-scale-[1] group-focus:u-scale-[1] group-hover:-u-translate-x-1/2 group-focus:-u-translate-x-1/2"
          >
            <i class="b-icon">
              <svg
                width="12"
                height="9"
                viewBox="0 0 12 9"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.2598 4.86121L6.9945 8.88257L5.581 7.53822L7.52767 5.78471H0V3.93769H7.52767L5.581 2.18419L6.9945 0.839844L11.2598 4.86121Z"
                />
              </svg>
            </i>
          </div>
        </div>

        <div
          class="b-listingCalloutCard__content | c-flow c-flow--4 | u-flex-col | u-gap-1"
        >
          <p
            data-address
            class="u-text-northwood-overline u-font-secondary u-uppercase u-text-primary-300"
          >
            ${city}, ${state}
          </p>

          <h3 class="u-text-northwood-h3" data-name>${title}</h3>
        </div>
      </a>
    </article>
  `;
};

export default NwoBasicCard;
