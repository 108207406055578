/**
 * This module is for maps coming from a CMS Page Builder Module
 *
 * @see NotionDescriptions https://www.notion.so/Adam-s-Remaining-FE-Guesstimates-4a3155adadde4ba3b5a471febf6e9fc1#b11fa0d359ed4727b20b04d8f7db8b5a
 */

/**
 * Imports
 */
import mapboxgl from 'mapbox-gl';
import Supercluster from 'supercluster';
import { debounce } from 'lodash';
import { PopupData, Property } from '../types';
import {
  createClusterElement,
  createGoBalMarkerElement,
  createPopupElement,
  flattenMapBounds,
  mapDefaults
} from '../utils/mapUtils';

export function createMap(
  container: HTMLElement,
  options: Partial<mapboxgl.MapboxOptions>
) {
  const map = new mapboxgl.Map({
    ...mapDefaults,
    ...options,
    container
  });

  map.addControl(
    new mapboxgl.ScaleControl({
      unit: 'imperial'
    })
  );

  map.addControl(
    new mapboxgl.NavigationControl({
      showCompass: false,
      visualizePitch: false
    }),
    'bottom-right'
  );

  // disable map zoom when using scroll
  map.scrollZoom.disable();

  return map;
}

function createPropertiesMapModule(node: HTMLElement) {
  const container = node.querySelector<HTMLElement>('[data-map]');
  const mapConfig = JSON.parse(container.getAttribute('data-map'));
  const markerData: Property[] = JSON.parse(
    node.querySelector<HTMLScriptElement>('[data-markers]').textContent
  );

  if (!mapConfig.accessToken) return;

  const points: GeoJSON.Feature<GeoJSON.Point>[] = markerData.map(
    (property: Property) => {
      return {
        type: 'Feature',
        properties: {
          cluster: false,
          ...property
        },
        geometry: {
          type: 'Point',
          coordinates: property.position
        }
      };
    }
  );

  const map = createMap(container, mapConfig);
  const clusterer = new Supercluster({
    radius: 60,
    maxZoom: 20
  });
  clusterer.load(points);

  map.on('load', () => {
    let markers: mapboxgl.Marker[] = [];

    function renderMarkers(newData: ReturnType<typeof clusterer.getClusters>) {
      markers.forEach((marker) => marker.remove());
      markers = [];

      newData.forEach((point) => {
        const isBallantyneBlockType =
          node.getAttribute('data-module-map') === 'ballantyne';
        const [longitude, latitude] = point.geometry.coordinates;
        const {
          cluster: isCluster,
          point_count: clusterCount
        } = point.properties;

        if (isCluster) {
          const clusterEL = createClusterElement({
            clusterCount,
            size: 40 + (clusterCount / points.length) * 2 * 100
          });

          if (!isBallantyneBlockType) {
            clusterEL.addEventListener('click', () => {
              const expansionZoom = Math.min(
                clusterer.getClusterExpansionZoom(Number(point.id)),
                20
              );
              const flyOffset =
                node.getAttribute('data-module-map') === 'ballantyne' ? 200 : 0;

              map.flyTo({
                center: [longitude, latitude],
                zoom: expansionZoom,
                offset: [flyOffset, 0],
                duration: 350
              });
            });
          }

          markers.push(
            new mapboxgl.Marker(clusterEL)
              .setLngLat([longitude, latitude])
              .addTo(map)
          );
        } else {
          const popupEl = createPopupElement({
            entry: point.properties as PopupData
          });
          const markerEl = createGoBalMarkerElement();
          let popup: mapboxgl.Popup = null;
          const flyOffset = isBallantyneBlockType ? 200 : 0;

          // Only add popups to `properties` block type
          if (!isBallantyneBlockType) {
            popup = new mapboxgl.Popup({
              closeButton: false,
              /** on ipad or greater, stick to design. otherwise adjust for screensize */
              anchor: window.innerWidth >= 768 ? 'left' : 'bottom',
              offset: window.innerWidth >= 768 ? [40, 0] : [0, -20],
              className: 'b-goBalMap__popup',
              focusAfterOpen: false
            })
              .setHTML(popupEl.innerHTML)
              .setMaxWidth('330px')
              .setLngLat([longitude, latitude])
              .on('open', () => {
                markerEl.classList.add('is-active');
                map.flyTo({
                  center: [longitude, latitude],
                  offset: [flyOffset, window.innerWidth >= 768 ? 0 : 40],
                  duration: 1000
                });

                /**
                 * GTM Tracking
                 */
                const popupTitle = (popup as any)._content.querySelector(
                  '[data-title]'
                ).innerText;
                const popupAddress = (popup as any)._content.querySelector(
                  'address'
                ).innerText;

                if (popupTitle && popupAddress) {
                  window.dataLayer.push({
                    event: 'mapClicked',
                    popupTitle,
                    popupAddress,
                    pageURL: window.location.href,
                    userId: '',
                    sessionId: sessionStorage.sessionId,
                    hitTimeStamp: getGtmUtcString(new Date())
                  });
                }
              })
              .on('close', () => {
                markerEl.classList.remove('is-active');
              });

            markers.push(
              new mapboxgl.Marker(markerEl)
                .setLngLat([longitude, latitude])
                .setPopup(popup)
                .addTo(map)
            );
          } else {
            markers.push(
              new mapboxgl.Marker(markerEl)
                .setLngLat([longitude, latitude])
                .addTo(map)
            );
          }
        }
      });
    }

    let clusters = clusterer.getClusters(flattenMapBounds(map), map.getZoom());
    let { zoom } = mapConfig;

    map.on('zoomend', (evt) => {
      if (map.getZoom() === zoom) return;
      zoom = evt.target.getZoom();
      clusters = clusterer.getClusters(flattenMapBounds(map), map.getZoom());
      renderMarkers(clusters);
    });

    renderMarkers(clusters);
    if (node.getAttribute('data-module-map') === 'properties') {
      markers[0].getPopup().addTo(map);
    }
  });
}

function createBusinessAndProjectMapModule(node: HTMLElement) {
  const container = node.querySelector<HTMLElement>('[data-map]');
  const mapConfig = JSON.parse(container.getAttribute('data-map'));
  const markerData: Property[] = JSON.parse(
    node.querySelector<HTMLScriptElement>('[data-markers]').textContent
  );

  const map = createMap(container, mapConfig);

  map.on('load', () => {
    let markers: mapboxgl.Marker[] = [];

    function renderMarkers() {
      markers.forEach((marker) => marker.remove());
      markers = [];

      markerData.forEach((point) => {
        const [longitude, latitude] = point.position;
        const markerEl = createGoBalMarkerElement();
        markers.push(
          new mapboxgl.Marker(markerEl)
            .setLngLat([longitude, latitude])
            .addTo(map)
        );
      });
    }

    let { zoom } = mapConfig;

    map.on('zoomend', (evt) => {
      if (map.getZoom() === zoom) return;
      zoom = evt.target.getZoom();
    });

    renderMarkers();

    // Offset the marker so it's not centered and the box doesn't cover it up
    // 640 = 40rem (tailwind sm breakpoint)
    const getFlyOffset = () => (window.innerWidth > 640 ? 200 : 0);

    map.flyTo({
      center: [markers[0].getLngLat().lng, markers[0].getLngLat().lat],
      offset: [getFlyOffset(), 0]
    });

    window.addEventListener(
      'resize',
      debounce(() => {
        map.flyTo({
          center: [markers[0].getLngLat().lng, markers[0].getLngLat().lat],
          offset: [getFlyOffset(), 0]
        });
      })
    );
  });
}

/**
 * Initialization
 *
 * @return    {undefined}          returns nothing, initializes Class
 */
export default function initModule() {
  const gobalMapModules: any[] = Array.from(
    document.querySelectorAll('[data-module-map]')
  );

  gobalMapModules.forEach((module) => {
    const moduleType = module.getAttribute('data-module-map');

    if (!moduleType) return;

    switch (moduleType) {
      case 'properties':
      case 'ballantyne':
        createPropertiesMapModule(module);
        break;
      case 'businessesAndProjects':
        createBusinessAndProjectMapModule(module);
        break;
      default:
        break;
    }
  });
}
