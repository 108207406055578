/**
 * Imports
 */
import * as focusTrap from 'focus-trap';

/**
 * ImageCarousel Class
 * - Supports functionality for image gallery within an Image Carousel module
 * - This class is made with GoBallantyne ImageCarousels in mind.
 * - #TODO May need to further rename/abstract so it's clear this isn't to be used for NWO
 */
class ImageCarousel {
  /**
   * Document Body
   */
  body: HTMLBodyElement | any;

  /**
   * Gallery images that are buttons and when clicked,
   * close the gallery and slide to the clicked slide
   */
  galleryImageButtons: HTMLButtonElement[];

  /**
   * Gallery Modal that holds images
   */
  galleryModal: HTMLElement;

  /**
   * Button that triggers closing the gallery modal
   */
  galleryCloseTrigger: HTMLButtonElement;

  /**
   * Button that triggers opening the gallery modal
   */
  galleryTrigger: HTMLButtonElement;

  /**
   * Parent Section `<section>` of the module
   */
  parentSection: HTMLElement;

  /**
   * Swiper instance and container
   */
  swiperInstance: HTMLElement | any;

  /**
   * Focus Trap instance
   */
  focusTrap: any = null;

  /**
   * Constructor
   *
   * @param     {HTMLElement}    parentSection    Parent Section `<section>` of the module
   *
   * @return    {undefined}          returns nothing, undefined
   */
  constructor(parentSection: HTMLElement) {
    this.body = document.body;
    this.parentSection = parentSection;
    this.galleryModal = this.parentSection.querySelector(
      '[data-gallery-modal]'
    );
    this.galleryTrigger = this.parentSection.querySelector(
      '[data-gallery-trigger]'
    );
    this.galleryCloseTrigger = this.parentSection.querySelector(
      '[data-gallery-close]'
    );
    this.galleryImageButtons = Array.from(
      this.parentSection.querySelectorAll('[data-gallery-image]')
    );
    this.swiperInstance = this.parentSection.querySelector('[data-swiper]');
  }

  /**
   * Handles closing the gallery and sliding to the desired image/slide
   *
   * @param     {HTMLButtonElement} targetButton
   *
   * @return    {undefined} returns nothing, undefined
   */
  handleSlidingToImage(targetButton: HTMLButtonElement = null) {
    if (!targetButton) return;

    const imageId: number = Number(targetButton.dataset.galleryImage);
    this.toggleGalleryModal();
    this.focusTrap.deactivate();
    this.swiperInstance.swiper.slideTo(imageId - 1);
  }

  /**
   * Sets the tabIndex of all of our Gallery controls and buttons
   *
   * @param     {string} index tabIndex value
   *
   * @return    {undefined}             returns nothing, undefined
   */
  setTabIndexes(index = '0') {
    this.galleryCloseTrigger.setAttribute('tabIndex', index);
    this.galleryImageButtons.forEach((imgBtn) => {
      imgBtn.setAttribute('tabIndex', index);
    });
  }

  /**
   * Toggles the gallery modal and a11y things
   *
   * @return    {undefined} returns nothing, undefined
   */
  toggleGalleryModal() {
    this.galleryModal.classList.toggle('active');
    this.body.classList.toggle('b-body--locked');

    // Accessibility
    if (this.galleryModal.getAttribute('aria-hidden') === 'true') {
      this.galleryModal.setAttribute('aria-hidden', 'false');
    } else {
      this.galleryModal.setAttribute('aria-hidden', 'true');
    }

    if (this.galleryModal.classList.contains('active')) {
      this.setTabIndexes();
    } else {
      this.setTabIndexes('-1');
    }
  }

  /**
   * Method for attaching our event listeners
   */
  attachListeners() {
    this.galleryTrigger.addEventListener('click', () => {
      this.toggleGalleryModal();
      this.focusTrap.activate();
    });

    this.galleryCloseTrigger.addEventListener('click', () => {
      this.toggleGalleryModal();
      this.focusTrap.deactivate();
    });

    // Gallery Image Buttons, when clicked handleSlidingToImage
    this.galleryImageButtons.forEach((imgBtn) => {
      imgBtn.addEventListener('click', (e) => {
        const targetButton = e.currentTarget as HTMLButtonElement;
        this.handleSlidingToImage(targetButton);
      });
    });
  }

  /**
   * Initialization : Attach listeners and any other methods that need to run on init
   *
   * @return    {[type]}          [return description]
   */
  init() {
    this.attachListeners();

    // Set initial tabIndex(es) to -1
    this.setTabIndexes('-1');
    // Setup our focus trap
    this.focusTrap = focusTrap.createFocusTrap(this.galleryModal, {
      escapeDeactivates: () => {
        this.toggleGalleryModal();

        return true;
      }
    });
  }
}

/**
 * Initialization, query element(s) if needed and create & init new class(es)
 *
 * @return    {undefined}          [returns nothing, initializes header]
 */
export default function initExample(): void {
  const carouselModules = Array.from(
    document.querySelectorAll(
      `[data-site="goballantyne"] [data-image-carousel]`
    )
  );

  if (!carouselModules.length) return;

  carouselModules.forEach((moduleContainer: any) => {
    const imageCarouselModule = new ImageCarousel(moduleContainer);
    imageCarouselModule.init();
  });
}
