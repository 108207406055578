/**
 * Imports
 */
import { CountUp } from 'countup.js';

/**
 * Countup Class
 */
class Countup {
  container: HTMLElement;

  constructor(container: HTMLElement) {
    this.container = container;
  }

  /**
   * Initialization : Attach listeners and any other methods that need to run on init
   *
   * @return    {undefined}
   */
  init() {
    try {
      const countNumber = this.container.innerText.match(/\d+/g)[0];
      const countText = this.container.innerText.match(/\D+/g)
        ? this.container.innerText.match(/\D+/g)[0]
        : null;
      const textFirst =
        this.container.innerText.indexOf(countNumber) >
        this.container.innerText.indexOf(countText);

      const numberContainer = document.createElement('span');
      numberContainer.setAttribute('data-countup-number', '');
      numberContainer.innerHTML = countNumber;

      this.container.innerText = this.container.innerText.replace(
        countNumber,
        ''
      );
      // .trim();

      if (textFirst) {
        this.container.appendChild(numberContainer);
      } else {
        this.container.prepend(numberContainer);
      }

      const countUpInstance = new CountUp(
        numberContainer,
        parseInt(countNumber, 10),
        {
          enableScrollSpy: true
        }
      );
      countUpInstance.start();
    } catch (err) {
      if (window.location.hostname.includes('dev')) {
        // eslint-disable-next-line no-console
        console.log(err);
      }
    }
  }
}

/**
 * Initialization, query element(s) if needed and create & init new class(es)
 *
 * @return    {undefined}          [returns nothing, initializes class]
 */
export default function initCountup(): Countup {
  const countupContainers = document.querySelectorAll('[data-countup]');

  if (!countupContainers.length) {
    return;
  }

  countupContainers.forEach((container: HTMLElement) => {
    const containerClass = new Countup(container);
    containerClass.init();
  });
}
