import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import instantsearch, { InstantSearch } from 'instantsearch.js';
import { configure, hits, stats } from 'instantsearch.js/es/widgets';
import { debounce } from 'lodash';
import { IListingHitTrackingData } from '../../types';
import {
  BasicCard,
  BusinessCard,
  EventCard,
  PropertyCard
} from './Templates/GoBallantyne/Cards';
import { NwoBasicCard } from './Templates/Northwood/NwoBasicCard';
import getWidgets from './Utilities/GetWidgets';

interface SearchConfig {
  index: string;
  input: {
    placeholder: string;
  };
  filters?: {
    [key: string]: string[];
  };
  facets: Array<{
    type: string;
    facet: string;
    options: string[];
    selector: string;
  }>;
  sortOptions: Array<{
    label: string;
    value: string;
  }>;
}

interface RouteState {
  page: number;
  q: string;
  [key: string]: number | string;
}

/**
 * Top-level Class, needs abstracting
 */
const initInstantSearch = () => {
  const searchListingContainer: HTMLElement = document.querySelector(
    '[data-instant-search-listing]'
  );

  if (!searchListingContainer) {
    return;
  }

  // Query the search config
  const searchConfig: SearchConfig = JSON.parse(
    searchListingContainer.dataset.instantSearchListing
  );
  // Loop through the config and get the desired widgets
  const widgets = getWidgets(searchConfig);
  const isNorthwoodSite = window.location.hostname.includes('northwoodoffice');

  /**
   * Start setting up our instantsearch client etc.
   */
  let search: InstantSearch = null;
  if (searchConfig.index) {
    search = instantsearch({
      indexName: searchConfig.index,
      searchClient: instantMeiliSearch(
        process.env.MEILI_FRONTEND_HOST,
        process.env.MEILI_API_KEY
      ),
      routing: {
        stateMapping: {
          stateToRoute(uiState): RouteState {
            const indexUiState = uiState[searchConfig.index];
            const hasRange = searchConfig.facets.some(
              (facet) => facet.type === 'range'
            );
            return {
              q: indexUiState.query,
              ...searchConfig.facets
                .filter((f) => f.type === 'multipleSelect')
                .reduce((acc: { [key: string]: any }, facet) => {
                  const key = facet.facet;
                  return {
                    ...acc,
                    [key]:
                      indexUiState.refinementList &&
                      indexUiState.refinementList[key]
                  };
                }, {} as { [key: string]: any }),
              ...(hasRange
                ? {
                    ...searchConfig.facets
                      .filter((f) => f.type === 'range')
                      .reduce((acc: { [key: string]: any }, facet) => {
                        const key = facet.facet;
                        return {
                          ...acc,
                          [key]: indexUiState.range && indexUiState.range[key]
                        };
                      }, {} as { [key: string]: any })
                  }
                : {}),
              page: indexUiState.page
            };
          },
          routeToState(routeState: RouteState) {
            const hasRange = searchConfig.facets.some(
              (facet) => facet.type === 'range'
            );
            return {
              [searchConfig.index]: {
                query: routeState.q,
                refinementList: {
                  ...searchConfig.facets
                    .filter((f) => f.type === 'multipleSelect')
                    .reduce((acc: { [key: string]: any }, facet) => {
                      const key = facet.facet;
                      return {
                        ...acc,
                        [key]: routeState[key]
                      };
                    }, {})
                },
                ...(hasRange
                  ? {
                      range: {
                        ...searchConfig.facets
                          .filter((f) => f.type === 'range')
                          .reduce((acc: { [key: string]: any }, facet) => {
                            const key = facet.facet;
                            return {
                              ...acc,
                              [key]: routeState[key]
                            };
                          }, {})
                      }
                    }
                  : {}),
                page: routeState.page
              }
            };
          }
        }
      }
    });
  }

  if (!search) {
    return;
  }

  search.addWidgets([
    configure({
      hitsPerPage: 5
    }),
    stats({
      container: '#stats',
      templates: {
        text: ({ nbHits }) =>
          // eslint-disable-next-line no-nested-ternary
          searchConfig.index.includes('buildings')
            ? `${nbHits} Available` // Properties|Buildings
            : nbHits === 1
            ? `${nbHits} Result`
            : `${nbHits} Results` // General Results
      },
      cssClasses: {
        root: 'u-text-[3rem]'
      }
    }),
    hits({
      container: '#hits',
      cssClasses: {
        list: 'u-flex u-flex-col u-gap-10'
      },
      templates: {
        item(item, { html }) {
          if (isNorthwoodSite) {
            return NwoBasicCard(item, { html });
          }

          // GoBal cards
          if (searchConfig.index.includes('businesses')) {
            return BusinessCard(item, { html });
          }

          if (searchConfig.index.includes('buildings')) {
            return PropertyCard(item, { html });
          }

          if (searchConfig.index.includes('events')) {
            return EventCard(item, { html });
          }

          // Basic card just renders an image, title and URL
          return BasicCard(item, { html });
        }
      }
    }),
    ...widgets,
    searchConfig.filters &&
      configure({
        disjunctiveFacetsRefinements: {
          ...searchConfig.filters
        }
      })
  ]);

  search.start();

  /**
   * GTM Tracking
   */
  search.on(
    'render',
    debounce(() => {
      const listingHits = Array.from(document.querySelectorAll('.hit'));

      /**
       * GTM Tracking - Listeners for each result to track their click
       */
      listingHits.forEach((hit) => {
        hit.addEventListener('click', (e) => {
          e.preventDefault();
          // track things here
          const clickedResult = e.currentTarget as HTMLElement;
          const resultType = clickedResult.dataset.type;
          const resultTitle =
            (clickedResult.querySelector('[data-name]') as HTMLElement)
              ?.innerText ?? '';
          const resultAddress =
            (clickedResult.querySelector('[data-address]') as HTMLElement)
              ?.innerText ?? '';
          const resultCategory =
            (clickedResult.querySelector('[data-category]') as HTMLElement)
              ?.innerText ?? '';

          // Track result click as `general`
          window.dataLayer.push({
            event: 'listingResultClicks',
            resultType,
            resultTitle,
            resultAddress,
            resultCategory,
            pageUrl: window.location.href,
            userId: '',
            sessionId: sessionStorage.sessionId,
            hitTimeStamp: getGtmUtcString(new Date())
          });

          // Track result click as `product`
          window.dataLayer.push({
            event: 'productClick',
            ecommerce: {
              click: {
                actionField: { list: resultType },
                products: [
                  {
                    name: resultTitle,
                    id: '',
                    price: '',
                    brand: resultAddress,
                    category: resultCategory
                  }
                ]
              }
            }
          });

          // Check if external URL
          if (hit.getAttribute('target') === '_blank') {
            // if so, take to external URL
            window.open((hit as HTMLAnchorElement).href);
          } else {
            // if not, change window.location
            /* @ts-ignore-next-line */
            window.location = (hit as HTMLAnchorElement).href;
          }
        });
      });

      /**
       * GTM Tracking - Record when/what a user types into search
       */
      if (search.helper.state.query !== '') {
        window.dataLayer.push({
          event: 'siteSearch',
          userId: '',
          siteSearch: search.helper.state.query,
          numOfResults: search.helper.lastResults.hits.length,
          pageURL: window.location.href,
          sessionId: sessionStorage.sessionId,
          hitTimeStamp: getGtmUtcString(new Date())
        });
      }

      /**
       * GTM Tracking - Record listing hits as impressions
       */
      window.dataLayer.push({
        pageType: 'plp',
        pageCategory: 'plp',
        pageTitle: document.title,
        pageName: `plp: ${document.title}`,
        hitPayLoad: '50',
        pageCountryCode: 'US',
        userId: '',
        clientId: '',
        sessionId: sessionStorage.sessionId,
        hitTimeStamp: getGtmUtcString(new Date()),
        redirectCount: '0',
        navigationType: (window as any).performance.getEntriesByType(
          'navigation'
        )[0].type,
        ecommerce: {
          currencyCode: 'USD',
          impressions: listingHits
            ? listingHits.map((hit: any, index: number) => {
                const hitData: IListingHitTrackingData = {
                  brand: hit?.querySelector('[data-address]')?.innerText ?? '',
                  category:
                    hit?.querySelector('[data-category]')?.innerText ?? '',
                  id: hit?.dataset.id ?? '',
                  name: hit?.querySelector('[data-name]')?.innerText ?? '',
                  position: (index + 1).toString(),
                  price: hit?.querySelector('[data-price]')?.innerText ?? ''
                };

                return hitData;
              })
            : []
        }
      });
    }, 400)
  );
};

export default initInstantSearch;
