class AvailableSpaces {
  /**
   * Parent Section `<section>` of the module
   */
  parentSection: HTMLElement;

  /**
   * The actual list that contains all the rows
   */
  listItems: NodeListOf<HTMLElement>;

  /**
   * Constructor
   *
   * @return    {undefined}          returns nothing, undefined
   */
  constructor() {
    this.parentSection = document.querySelector('[data-available-spaces]');
    this.listItems = this.parentSection?.querySelectorAll('[data-list-item]');

    if (!this.parentSection || !this.listItems) {
      return;
    }

    this.listItems.forEach((item) => {
      const itemTrigger = item.querySelector('[data-trigger]');

      itemTrigger.addEventListener('click', () => {
        this.listItems.forEach((listItem) =>
          listItem.classList.toggle(
            'active',
            listItem === item && !listItem.classList.contains('active')
          )
        );

        // Wait a moment for the accordion functionality to complete, then scroll
        setTimeout(() => {
          item.scrollIntoView();
        }, 500);
      });
    });
  }
}

export default function initAvailableSpaces() {
  const newAvailableSpaces = new AvailableSpaces();

  return newAvailableSpaces;
}
