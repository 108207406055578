type TAnimateFunction = () => void;
type TAnimateElements = (
  animateFunctions: TAnimateFunction[],
  sequenceDelay: number
) => Promise<false>;

/**
 * animationSequence
 * Animate
 */
const animateSequentially: TAnimateElements = async (
  animateFunctions,
  delay
) => {
  /* eslint-disable no-plusplus */
  for (let i = 0; i < animateFunctions.length; i++) {
    const f = animateFunctions[i];
    const animationDelay = i * delay;
    /* eslint-disable no-await-in-loop */
    await new Promise<void>((resolve) => {
      setTimeout(() => {
        f();
        resolve();
      }, animationDelay);
    });
    /* eslint-enable no-await-in-loop */
  }
  /* eslint-enable no-plusplus */

  return false; // Return true after all animations have completed
};

export default animateSequentially;
