import { connectSortBy } from 'instantsearch.js/es/connectors';

/**
 * Interface for RenderOptions properties
 * @see https://www.algolia.com/doc/api-reference/widgets/sort-by/js/#create-a-render-function
 */
interface IRenderOptions {
  options: {
    label: string;
    value: string;
  }[];
  currentRefinement: string;
  canRefine: boolean;
  refine: Function;
  widgetParams: any;
}

export const renderSortBy = (
  renderOptions: IRenderOptions,
  isFirstRender: boolean
) => {
  const {
    canRefine,
    currentRefinement,
    options,
    refine,
    widgetParams
  } = renderOptions;

  if (isFirstRender) {
    const select = document.createElement('select');

    select.addEventListener('change', (event) => {
      // Refine with the <select> value
      refine((event.target as HTMLSelectElement).value);
      // Query selected <option> el for tracking data
      const selectedOptionEl = Array.from(
        (event.target as HTMLElement).querySelectorAll('option')
      ).filter((option) => option.selected)[0];

      /**
       * GTM Tracking
       */
      window.dataLayer.push({
        event: 'sortByToggle',
        toggleOptionSelected: selectedOptionEl.dataset.trackingValue,
        userId: '',
        sessionId: sessionStorage.sessionId,
        hitTimeStamp: getGtmUtcString(new Date())
      });
    });

    // Add cssClasses to respective elements
    select.setAttribute('class', widgetParams.cssClasses.select);
    select.querySelectorAll('option').forEach((option) => {
      option.setAttribute('class', widgetParams.cssClasses.option);
    });

    widgetParams.container.setAttribute('class', widgetParams.cssClasses.root);
    widgetParams.container.appendChild(select);

    // Let's also sort|refine the results by default, use the first option
    refine(options[0].value);
  }

  const select = widgetParams.container.querySelector('select');

  select.disabled = !canRefine;

  select.innerHTML = `
    ${options
      .map(
        (option) => `
          <option
            value="${option.value}"
            data-tracking-value="${option.label}"
            ${option.value === currentRefinement ? 'selected' : ''}
          >
            ${option.label}
          </option>
        `
      )
      .join('')}
  `;
};

const CustomSortBy = connectSortBy(renderSortBy);

export default CustomSortBy;
