/**
 * Imports
 */

/**
 * ImageWithHotspots Class
 */
class ImageWithHotspots {
  /**
   * Action Blocks for each of our pins
   */
  actionBlocks: HTMLElement[];

  /**
   * Container Element
   */
  container: HTMLElement;

  /**
   * Pins/Triggers for swapping between pin content
   */
  pins: HTMLButtonElement[];

  /**
   * Constructor for setting properties/elements of our class
   *
   * @param     {HTMLElement}    sectionContainer
   *
   * @return    {undefined}
   */
  constructor(sectionContainer: HTMLElement) {
    this.container = sectionContainer;
    this.actionBlocks = Array.from(
      this.container.querySelectorAll('[data-content]')
    );
    this.pins = Array.from(this.container.querySelectorAll('[data-pin]'));
  }

  /**
   * Method for attaching our event listeners
   */
  attachListeners() {
    this.pins.forEach((pin) => {
      pin.addEventListener('click', () => {
        // Get the target content elements container
        const targetContentEl =
          this.actionBlocks.filter(
            (actionEl) => actionEl.dataset.content === pin.dataset.pin
          )[0] ?? null;

        if (targetContentEl) {
          // Toggle hidden/visible for action blocks
          this.actionBlocks.forEach((block) => {
            block.classList.toggle('u-hidden', block !== targetContentEl);
          });
          // Toggle is-active for the pins
          this.pins.forEach((otherPin) => {
            otherPin.classList.toggle(
              'is-active',
              otherPin.dataset.pin === targetContentEl.dataset.content
            );
          });
        }
      });
    });
  }

  /**
   * Initialization : Attach listeners and any other methods that need to run on init
   *
   * @return    {undefined} returns nothing
   */
  init() {
    this.attachListeners();
  }
}

/**
 * Initialization, query element(s) if needed and create & init new class(es)
 *
 * @return    {undefined}          [returns nothing, initializes header]
 */
export default function initImageWithHotspots(): undefined {
  const imageWithHotspotSections = document.querySelectorAll('[data-hotspots]');

  if (!imageWithHotspotSections.length) {
    return;
  }

  imageWithHotspotSections.forEach((section: HTMLElement) => {
    const sectionModule = new ImageWithHotspots(section);
    sectionModule.init();
  });
}
