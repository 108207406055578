/**
 * Imports
 */
// import { debounce } from 'lodash';

/**
 * Timeline Class
 */
class Timeline {
  /**
   * Parent <section> of the module
   */
  parentSection: HTMLElement;

  /**
   * Image <div> containers around our images
   */
  milestoneImageContainers: HTMLElement[];

  /**
   * Milestone <li>s
   */
  milestones: HTMLElement[];

  /**
   * Milestone Trigger <buttons>
   */
  milestoneTriggers: HTMLButtonElement[];

  constructor(parentSection: HTMLElement) {
    this.parentSection = parentSection;
    this.milestones = Array.from(
      this.parentSection.querySelectorAll('[data-milestone')
    );
    this.milestoneImageContainers = Array.from(
      this.parentSection.querySelectorAll('[data-milestone-imageContainer')
    );
    this.milestoneTriggers = Array.from(
      this.parentSection.querySelectorAll('[data-milestone-trigger')
    );
  }

  /**
   * Handle Swithing between milestones
   */
  handleSwitchingMilestones(targetTrigger: HTMLButtonElement) {
    this.milestones.forEach((milestone) => {
      milestone.classList.toggle(
        'active',
        milestone.dataset.milestone === targetTrigger.dataset.milestoneTrigger
      );
    });

    this.milestoneImageContainers.forEach((imgContainer) => {
      imgContainer.classList.toggle(
        'active',
        imgContainer.dataset.milestoneImagecontainer ===
          targetTrigger.dataset.milestoneTrigger
      );
    });
  }

  /**
   * Method for attaching our event listeners
   */
  attachListeners() {
    if (this.milestoneTriggers.length) {
      this.milestoneTriggers.forEach((trigger) => {
        trigger.addEventListener('click', () => {
          this.handleSwitchingMilestones(trigger);
        });
      });
    }
  }

  /**
   * Initialization : Attach listeners and any other methods that need to run on init
   *
   * @return    {[type]}          [return description]
   */
  init() {
    this.attachListeners();
  }
}

/**
 * Initialization, query element(s) if needed and create & init new class(es)
 *
 * @return    {undefined}          [returns nothing, initializes header]
 */
export default function initTimeline(): void {
  const timelineSections = document.querySelectorAll(
    '[data-site="northwood"] [data-timeline]'
  );

  if (!timelineSections.length) {
    return;
  }

  timelineSections.forEach((section: HTMLElement) => {
    const exampleModule = new Timeline(section);
    exampleModule.init();
  });
}
