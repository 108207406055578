import {
  menuSelect,
  pagination,
  rangeSlider,
  searchBox
} from 'instantsearch.js/es/widgets';
import staticRefinementList from '../CustomWidgets/StaticRefinementList';
import CustomSortBy from '../CustomWidgets/CustomSortBy';
import initMapUI from '../MapUI';
import nwoPagination from '../CustomWidgets/NwoPagination';
import customRangeInput from '../CustomWidgets/CustomRangeInput';

/**
 * getWidgets
 */
const getWidgets = (searchConfig: any): any => {
  const allWidgets: any[] = [];
  const isNorthwoodSite = window.location.hostname.includes('northwoodoffice');
  const isCaseStudiesListing = window.location.href.includes('case-studies');

  if (isNorthwoodSite) {
    allWidgets.push(
      nwoPagination({
        // @ts-ignore
        container: document.querySelector('#pagination'),
        scrollTo: '[data-instant-search-listing]',
        showLast: true
      })
    );
  } else {
    // GoBal Pagination
    allWidgets.push(
      pagination({
        container: '#pagination',
        padding: 2,
        cssClasses: {
          disabledItem: 'u-opacity-50 u-cursor-not-allowed',
          firstPageItem: 'u-rounded-md',
          lastPageItem: 'u-rounded-md',
          link:
            'u-flex u-items-center u-justify-center u-h-full u-w-full u-text-inherit u-font-semibold u-transition-colors u-duration-300 | hover:u-bg-primary-800 hover:u-text-neutrals-secondary-600 focus:u-bg-primary-800 focus:u-text-neutrals-secondary-600',
          list: 'u-flex u-gap-6 u-justify-center',
          item:
            'u-flex u-items-center u-justify-center u-w-[2.5rem] u-h-[2.5rem] u-bg-primary-300 u-overflow-hidden',
          nextPageItem: 'u-rounded-md u-ml-10',
          pageItem: 'u-rounded-full',
          previousPageItem: 'u-rounded-md u-mr-10',
          selectedItem: '!u-bg-primary-800 !u-text-neutrals-secondary-600'
        },
        scrollTo: '[data-instant-search-listing]',
        showFirst: false,
        showLast: false,
        templates: {
          next: `<svg id="chevron-right" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><path d="M7.02,6L.99,12V0L7.02,6Z" fill="currentColor" /></svg>`,
          previous: `<svg id="chevron-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 8 12"><path d="M.98,6l6.03,6V0L.98,6Z" fill="currentColor" /></svg>`
        }
      })
    );
  }

  // Check for map toggle
  if (searchConfig.mapToggle === true) {
    allWidgets.push(initMapUI(true));
  }

  // Main search input
  // TODO: consider abstracting to some type of instantsearch config/utility file
  const goBalSearchInput = {
    input:
      'u-text-sm u-tracking[0.0625rem] u-rounded-full u-bg-primary-300 u-pl-8 u-w-full | lg:u-w-[22.5rem] lg:u-h-[3.3rem]',
    submit:
      'u-absolute u-top-1/2 -u-translate-y-1/2 u-w-[0.75rem] u-h-[0.75rem] u-left-3',
    submitIcon: 'u-w-full u-h-full',
    reset:
      'u-absolute u-top-1/2 -u-translate-y-1/2 u-w-[0.75rem] u-h-[0.75rem] u-right-3',
    resetIcon: 'u-w-full u-h-full u-bg-primary-300',
    root: 'u-relative u-w-full'
  };
  const northwoodSearchInput = {
    input:
      'u-text-northwood-body2 u-tracking[0.0625rem] u-bg-nwoCarouselBg u-pl-10 u-w-full | lg:u-w-[22.5rem] lg:u-h-[3.3rem]',
    submit:
      'u-absolute u-top-1/2 -u-translate-y-1/2 u-w-[0.875rem] u-h-[0.875rem] u-left-4',
    submitIcon: 'u-w-full u-h-full u-rotate-[90deg] u-fill-secondary-800',
    reset:
      'u-absolute u-top-1/2 -u-translate-y-1/2 u-w-[0.75rem] u-h-[0.75rem] u-right-3',
    resetIcon: 'u-w-full u-h-full u-bg-nwoCarouselBg',
    root: 'u-relative u-w-full'
  };

  // NWO case studies input handled in NwoCaseStudies.ts
  if (searchConfig.input && !isCaseStudiesListing) {
    allWidgets.push(
      searchBox({
        container: '#searchBox',
        showLoadingIndicator: false,
        placeholder: searchConfig.input.placeholder,
        cssClasses: isNorthwoodSite ? northwoodSearchInput : goBalSearchInput
      })
    );
  }

  // Sort options
  if (searchConfig.sortOptions?.length) {
    allWidgets.push(
      CustomSortBy({
        /* @ts-ignore-next-line */
        container: document.getElementById('sort'),
        cssClasses: {
          root: 'instantsearch__selectRoot',
          select: isNorthwoodSite
            ? 'u-font-bold u-p-1 u-pr-5 u-text-primary-900 u-text-base u-py-2 u-pl-3 u-pr-7'
            : 'u-font-bold u-p-1 u-pr-5 u-text-primary-800 u-text-sm u-py-2 u-pl-3 u-pr-7',
          option: 'u-text-center'
        },
        items: searchConfig.sortOptions
      })
    );
  }

  // Get facets (filters)
  if (searchConfig.facets.length) {
    searchConfig.facets.forEach((facet: any) => {
      switch (facet.type) {
        case 'range':
          if (facet.inputTypes.includes('rangeInputs')) {
            allWidgets.push(
              customRangeInput({
                /* @ts-ignore-next-line */
                container: document.querySelector(facet.selectors.input),
                attribute: facet.facet,
                min: facet.min,
                max: facet.max
              })
            );
          }

          if (facet.inputTypes.includes('rangeSlider')) {
            allWidgets.push(
              rangeSlider({
                container: facet.selectors.slider,
                attribute: facet.facet,
                min: facet.min,
                max: facet.max
              })
            );
          }
          break;
        case 'multipleSelect':
          allWidgets.push(
            staticRefinementList(facet.selector)({
              attribute: facet.facet,
              items: facet.options.map((filter: string) => ({
                label: filter,
                value: filter
              }))
            })
          );
          break;
        case 'menuSelect':
          allWidgets.push(
            menuSelect({
              container: '#relatedMarket',
              attribute: 'relatedMarket',
              cssClasses: {
                root: 'instantsearch__selectRoot',
                select: isNorthwoodSite
                  ? 'u-font-bold u-p-1 u-pr-5 u-text-primary-900 u-py-2 u-pl-3 u-pr-7'
                  : 'u-font-bold u-p-1 u-pr-5 u-text-primary-800 u-text-sm u-py-2 u-pl-3 u-pr-7',
                option: 'u-text-center'
              }
            })
          );
          break;
        default:
          break;
      }
    });
  }

  return allWidgets;
};

export default getWidgets;
