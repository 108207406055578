/**
 * BasicCard that only expects an image, title and URL
 */
export const BasicCard = (item: any, { html }: { html: any }) => {
  const { image, title, url } = item;

  // All or nothin' baby
  if (!image || !title || !url) {
    return null;
  }

  return html`
    <a
      href="${url}"
      class="hit | u-w-full u-flex u-items-center u-gap-16 u-group"
      data-type="basic"
    >
      <div
        class="u-relative u-w-[12.5rem] u-h-[12.5rem] u-overflow-hidden u-shrink-0"
      >
        <img
          src="${image}"
          class="u-w-full u-h-full u-object-cover u-object-center u-scale-[1.1] | u-transition-all u-duration-500 group-hover:u-scale-[1] group-focus:u-scale-[1]"
        />
      </div>

      <div class="c-flow c-flow--3">
        <p
          class="u-capitalize u-text-[1.375rem] u-leading-[1.45] u-font-medium | u-transition-colors u-duration-300 group-hover:u-text-primary-600 group-focus:u-text-primary-600"
          data-name
        >
          ${title}
        </p>
      </div>
    </a>
  `;
};

export default BasicCard;
