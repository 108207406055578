import { connectRange } from 'instantsearch.js/es/connectors';
import { debounce } from 'lodash';

// Create the render function
const renderRangeInput = (renderOptions: any, isFirstRender: boolean) => {
  const { start, range, refine, widgetParams } = renderOptions;
  const [min, max] = start;

  if (isFirstRender) {
    const form = document.createElement('form');

    form.addEventListener('submit', (event) => {
      event.preventDefault();

      const rawMinInputValue = parseFloat(
        (event.target as any).elements.min.value
      );
      const rawMaxInputValue = parseFloat(
        (event.target as any).elements.max.value
      );

      refine([
        Number.isFinite(rawMinInputValue) ? rawMinInputValue : undefined,
        Number.isFinite(rawMaxInputValue) ? rawMaxInputValue : undefined
      ]);
    });

    widgetParams.container.appendChild(form);

    return;
  }

  const form = widgetParams.container.querySelector('form');
  form.classList.add('u-flex');
  form.classList.add('u-gap-3');
  form.innerHTML = `
    <input
      type="string"
      name="min"
      placeholder="${1}"
      value="${Number.isFinite(min) ? min.toLocaleString('en-US') : ''}"
      class="u-bg-primary-300 u-rounded-md u-w-full u-text-center"
    />
    <input
      type="string"
      name="max"
      placeholder="${range.max.toLocaleString('en-US')}"
      value="${Number.isFinite(max) ? max.toLocaleString('en-US') : ''}"
      class="u-bg-primary-300 u-rounded-md u-w-full u-text-center"
    />
    <input type="submit" hidden />
  `;

  const inputs = Array.from(form.querySelectorAll('input[type="number"]'));
  const minInput = inputs.find(
    (input: HTMLInputElement) => input.getAttribute('name') === 'min'
  ) as HTMLInputElement;
  const maxInput = inputs.find(
    (input: HTMLInputElement) => input.getAttribute('name') === 'max'
  ) as HTMLInputElement;

  inputs.forEach((input: HTMLInputElement) => {
    input.addEventListener(
      'keyup',
      debounce(() => {
        const minVal = parseFloat(minInput.value.replace(/,/g, ''));
        const maxVal = parseFloat(maxInput.value.replace(/,/g, ''));
        refine([minVal, maxVal]);
      }, 500)
    );
  });
};

// Create the custom widget
const customRangeInput = connectRange(renderRangeInput);

export default customRangeInput;
