import { connectPagination } from 'instantsearch.js/es/connectors';

// interface IRenderOptions {
//   pages: number[];
//   currentRefinement: number;
//   nbHits: number;
//   npPages: number;
//   isFirstPage: boolean;
//   isLastPage: boolean;
//   canRefine: boolean;
//   refine: Function;
//   createURL: Function;
//   widgetParams: Function;
// }

// connector for custom UI here
const renderPagination = (renderOptions: any, isFirstRender: boolean) => {
  const { createURL, currentRefinement, pages, refine } = renderOptions;
  const container = document.querySelector('#pagination');
  const isCaseStudies = window.location.href.includes('case-studies');

  if (isFirstRender) {
    // Initial rendering
  }

  // Render
  container.innerHTML = `
    <ul class="u-flex u-items-center u-flex-wrap u-gap-6 u-font-secondary u-mt-8 | sm:u-flex-nowrap ${
      isCaseStudies ? 'u-max-w-[48.5rem] u-mx-auto' : ''
    }">
      ${
        isCaseStudies
          ? `
            <li class="u-shrink-0 u-flex u-items-center u-justify-center u-w-[2.875rem] u-h-[2.875rem] u-overflow-hidden u-bg-primary-300">
              <a
                href="${createURL(currentRefinement - 1)}"
                data-value="${currentRefinement - 1}"
                class="u-flex u-items-center u-justify-center u-h-full u-w-full ${
                  currentRefinement === 0
                    ? 'u-pointer-events-none u-opacity-50 u-cursor-not-allowed'
                    : ''
                }"
              >
                <i class="b-icon | u-text-grayscale-100">
                  <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 8.35702L7.40781 0.949219L9.86272 3.42567L6.48183 6.65581L16 6.65581V10.0582L6.48183 10.0582L9.86272 13.2884L7.40781 15.7648L0 8.35702Z" fill="currentColor"/>
                  </svg>
                </i>
              </a>
            </li>

            <div class="divider | u-h-[1px] u-w-full u-shrink u-bg-grayscale-300 u-mx-6"></div>
          `
          : ''
      }

      ${pages
        .map(
          (page: number) => `
            <li
              class="u-shrink-0 u-flex u-items-center u-justify-center u-w-[2.5rem] u-h-[2.5rem] u-overflow-hidden ${
                page === currentRefinement
                  ? 'u-bg-primary-300 u-text-grayscale-100'
                  : 'u-bg-[#EBEBEB]'
              }"
            >
              <a
                href="${createURL(page)}"
                data-value="${page}"
                class="u-pt-[3px] u-flex u-items-center u-justify-center u-h-full u-w-full u-text-inherit u-font-semibold u-transition-colors u-duration-300 | hover:u-bg-primary-800 hover:u-text-neutrals-secondary-600 focus:u-bg-primary-800 focus:u-text-neutrals-secondary-600"
              >
                ${page + 1}
              </a>
            </li>
          `
        )
        .join('')}

        <span class="u-uppercase u-text-grayscale-400 u-tracking-widest">OF</span>

        <li class="u-shrink-0 u-flex u-items-center u-justify-center u-w-[2.5rem] u-h-[2.5rem] u-bg-[#EBEBEB] u-overflow-hidden">
          <a
            href="${createURL(pages.length - 1)}"
            data-value="${pages.length - 1}"
            class="u-pt-[3px] u-flex u-items-center u-justify-center u-h-full u-w-full u-text-inherit u-font-semibold u-transition-colors u-duration-300 | hover:u-bg-primary-800 hover:u-text-neutrals-secondary-600 focus:u-bg-primary-800 focus:u-text-neutrals-secondary-600"
          >
            ${pages.length}
          </a>
        </li>

        <div class="divider | u-h-[1px] u-w-full u-shrink u-bg-grayscale-300 u-mx-6"></div>

        ${
          !isCaseStudies
            ? `
              <li class="u-shrink-0 u-flex u-items-center u-justify-center u-w-[2.875rem] u-h-[2.875rem] u-overflow-hidden u-bg-primary-300">
                <a
                  href="${createURL(currentRefinement - 1)}"
                  data-value="${currentRefinement - 1}"
                  class="u-flex u-items-center u-justify-center u-h-full u-w-full ${
                    currentRefinement === 0
                      ? 'u-pointer-events-none u-opacity-50 u-cursor-not-allowed'
                      : ''
                  }"
                >
                  <i class="b-icon | u-text-grayscale-100">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                      <path d="M0 8.35702L7.40781 0.949219L9.86272 3.42567L6.48183 6.65581L16 6.65581V10.0582L6.48183 10.0582L9.86272 13.2884L7.40781 15.7648L0 8.35702Z" fill="currentColor"/>
                    </svg>
                  </i>
                </a>
              </li>
            `
            : ''
        }

        <li class="u-shrink-0 u-flex u-items-center u-justify-center u-w-[2.875rem] u-h-[2.875rem] u-overflow-hidden u-bg-primary-300">
          <a
            href="${createURL(currentRefinement + 1)}"
            data-value="${currentRefinement + 1}"
            class="u-flex u-items-center u-justify-center u-h-full u-w-full${
              currentRefinement === pages.length - 1
                ? 'u-pointer-events-none u-opacity-50 u-cursor-not-allowed'
                : ''
            }"
          >
            <i class="b-icon | u-text-grayscale-100">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M16 8.35702L8.59219 15.7648L6.13728 13.2884L9.51817 10.0582H0V6.65581H9.51817L6.13728 3.42567L8.59219 0.949219L16 8.35702Z" fill="currentColor"/>
              </svg>
            </i>
          </a>
        </li>
    </ul>
  `;

  [...container.querySelectorAll('a')].forEach((element: HTMLElement) => {
    element.addEventListener('click', (event: any) => {
      event.preventDefault();
      refine(event.currentTarget.dataset.value);

      const listingSection = isCaseStudies
        ? (document.querySelector('[data-case-studies-listing]') as HTMLElement)
        : (document.querySelector(
            '[data-instant-search-listing]'
          ) as HTMLElement);

      window.scrollTo({
        top: listingSection.offsetTop,
        behavior: 'smooth'
      });
    });
  });
};

const nwoPagination = connectPagination(renderPagination);

export default nwoPagination;
