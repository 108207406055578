/**
 * Imports
 */
import * as focusTrap from 'focus-trap';

/**
 * HeroVideo Class
 */
class HeroVideo {
  body: HTMLBodyElement | any;

  container: HTMLElement | any;

  btn: HTMLElement | any;

  /**
   * Focus Trap instance
   */
  focusTrap: any = null;

  iframe: HTMLIFrameElement;

  modal: HTMLElement | any;

  modalClose: HTMLElement | any;

  videoId: string;

  player: any;

  constructor() {
    this.body = document.body;
    this.container = this.body.querySelector('[data-hero-video]');
    this.btn = this.container?.querySelector('[data-play-video]') ?? null;
    this.iframe = undefined;
    this.modal = undefined;
    this.modalClose = undefined;
    this.videoId = this.btn?.getAttribute('data-play-video') ?? null;
  }

  handleTogglingModal() {
    this.modal.classList.toggle('b-heroModal--active');
    this.handleLockingBody();
    if (this.modal.classList.contains('b-heroModal--active')) {
      this.focusTrap.activate();
    } else {
      this.focusTrap.deactivate();
      // Pause video @see https://gist.github.com/cferdinandi/9044694
      if (this.iframe) {
        this.iframe.contentWindow.postMessage(
          JSON.stringify({
            event: 'command',
            func: 'stopVideo'
          }),
          '*'
        );
      }
    }
  }

  handleAttachListeners() {
    this.btn.addEventListener('click', () => this.handleTogglingModal());
    this.modalClose.addEventListener('click', () => this.handleTogglingModal());
  }

  /**
   * Handle locking the body when something is clicked
   *
   * @return    {undefined}          returns nothing, undefined
   */
  handleLockingBody() {
    this.body.classList.toggle('b-body--locked');
  }

  /**
   * Method for attaching our event listeners
   */
  handleModalRender() {
    const modal = document.createElement('div');
    modal.className =
      'b-heroModal | u-flex u-items-center u-justify-center | u-fixed | u-opacity-0';
    modal.innerHTML = /* html */ `
      <div class="b-heroModal__bg | u-absolute"></div>
      <div id="player" class="b-heroModal__body | u-relative | u-bg-white">
        <button class="b-heroModal__close u-flex u-items-center u-absolute u-right-0 u-text-white"><svg class="u-mr-1 u-fill-current u-w-[1.75rem] u-h-[1.75rem]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19"><path d="M2.486 15.096 15.13 2.453l1.415 1.414L3.9 16.51z"/><path d="m2.486 3.867 1.415-1.414 12.643 12.643-1.415 1.414z"/></svg>
        Close</button>
        <iframe id="video-${this.videoId}" class="u-w-full u-h-full" src="https://www.youtube.com/embed/${this.videoId}?enablejsapi=1&rel=0&enablejsapi=1" title="YouTube video player" frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
      </div>
    `;

    this.body.appendChild(modal);
    this.modal = this.body.querySelector('.b-heroModal');
    this.modalClose = this.body.querySelector('.b-heroModal__close');
    this.iframe = this.modal.querySelector('iframe');
  }

  /**
   * Initialization : Attach listeners and any other methods that need to run on init
   *
   * @return    {[type]}          [return description]
   */
  init() {
    if (!this.container) {
      return;
    }

    this.handleModalRender();
    this.handleAttachListeners();

    // Setup our focus trap
    this.focusTrap = focusTrap.createFocusTrap(this.modal, {
      escapeDeactivates: () => {
        this.handleTogglingModal();
        return true;
      }
    });
  }
}

/**
 * Initialization, query element(s) if needed and create & init new class(es)
 *
 * @return    {undefined}          [returns nothing, initializes header]
 */
export default function initHeroVideo(): HeroVideo {
  const HeroVideoWithModal = new HeroVideo();

  HeroVideoWithModal.init();

  return HeroVideoWithModal;
}
