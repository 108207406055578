import { instantMeiliSearch } from '@meilisearch/instant-meilisearch';
import instantsearch, { InstantSearch } from 'instantsearch.js';
import { configure, hits, searchBox } from 'instantsearch.js/es/widgets';
import nwoPagination from './CustomWidgets/NwoPagination';
import { NwoDetailCard } from './Templates/Northwood/NwoDetailCard';
import getWidgets from './Utilities/GetWidgets';
// import { debounce } from 'lodash';

interface ICaseStudySearchConfig {
  index: string;
  input: {
    placeholder: string;
  };
  filters?: {
    [key: string]: string[];
  };
  facets?: Array<{
    type: string;
    facet: string;
    options: string[];
    selector: string;
  }>;
  sortOptions?: Array<{
    label: string;
    value: string;
  }>;
}

interface RouteState {
  page: number;
  q: string;
  [key: string]: number | string;
}

/**
 * Top-level Class, needs abstracting
 */
const initNwoCaseStudiesListing = () => {
  const searchListingContainer: HTMLElement = document.querySelector(
    '[data-case-studies-listing]'
  );

  if (!searchListingContainer) {
    return;
  }

  // Query the search config
  const searchConfig: ICaseStudySearchConfig = JSON.parse(
    searchListingContainer.dataset.caseStudiesListing
  );
  // Loop through the config and get the desired widgets
  const widgets = getWidgets(searchConfig);

  /**
   * Start setting up our instantsearch client etc.
   */
  let search: InstantSearch = null;
  if (searchConfig.index) {
    search = instantsearch({
      indexName: searchConfig.index,
      searchClient: instantMeiliSearch(
        process.env.MEILI_FRONTEND_HOST,
        process.env.MEILI_API_KEY
      ),
      routing: {
        stateMapping: {
          stateToRoute(uiState): RouteState {
            const indexUiState = uiState[searchConfig.index];
            const hasRange = searchConfig.facets.some(
              (facet) => facet.type === 'range'
            );
            return {
              q: indexUiState.query,
              ...searchConfig.facets
                .filter((f) => f.type === 'multipleSelect')
                .reduce((acc: { [key: string]: any }, facet) => {
                  const key = facet.facet;
                  return {
                    ...acc,
                    [key]:
                      indexUiState.refinementList &&
                      indexUiState.refinementList[key]
                  };
                }, {} as { [key: string]: any }),
              ...(hasRange
                ? {
                    ...searchConfig.facets
                      .filter((f) => f.type === 'range')
                      .reduce((acc: { [key: string]: any }, facet) => {
                        const key = facet.facet;
                        return {
                          ...acc,
                          [key]: indexUiState.range && indexUiState.range[key]
                        };
                      }, {} as { [key: string]: any })
                  }
                : {}),
              page: indexUiState.page
            };
          },
          routeToState(routeState: RouteState) {
            const hasRange = searchConfig.facets.some(
              (facet) => facet.type === 'range'
            );
            return {
              [searchConfig.index]: {
                query: routeState.q,
                refinementList: {
                  ...searchConfig.facets
                    .filter((f) => f.type === 'multipleSelect')
                    .reduce((acc: { [key: string]: any }, facet) => {
                      const key = facet.facet;
                      return {
                        ...acc,
                        [key]: routeState[key]
                      };
                    }, {})
                },
                ...(hasRange
                  ? {
                      range: {
                        ...searchConfig.facets
                          .filter((f) => f.type === 'range')
                          .reduce((acc: { [key: string]: any }, facet) => {
                            const key = facet.facet;
                            return {
                              ...acc,
                              [key]: routeState[key]
                            };
                          }, {})
                      }
                    }
                  : {}),
                page: routeState.page
              }
            };
          }
        }
      }
    });
  }

  if (!search) {
    return;
  }

  search.addWidgets([
    configure({
      hitsPerPage: 9
    }),
    hits({
      container: '#hits',
      cssClasses: {
        list: 'c-autoGrid c-autoGrid--layout-1/3 | !u-gap-x-10 !u-gap-y-16'
      },
      templates: {
        item(item, { html }) {
          return NwoDetailCard(item, { html });
        }
      }
    }),
    nwoPagination({
      // @ts-ignore
      container: document.querySelector('#pagination'),
      scrollTo: '[data-case-studies-listing]',
      showLast: true
    }),
    searchBox({
      container: '#searchBox',
      showLoadingIndicator: false,
      placeholder: searchConfig.input.placeholder,
      cssClasses: {
        input:
          'u-text-northwood-body2 u-tracking[0.0625rem] u-bg-nwoCarouselBg u-pl-10 u-w-full | lg:!u-w-[35.75rem] lg:u-h-[3.3rem]',
        submit:
          'u-absolute u-top-1/2 -u-translate-y-1/2 u-w-[0.875rem] u-h-[0.875rem] u-left-4',
        submitIcon: 'u-w-full u-h-full u-rotate-[90deg] u-fill-secondary-800',
        reset:
          'u-absolute u-top-1/2 -u-translate-y-1/2 u-w-[0.75rem] u-h-[0.75rem] u-right-3',
        resetIcon: 'u-w-full u-h-full u-bg-nwoCarouselBg',
        root: 'u-relative u-w-full'
      }
    }),
    ...widgets,
    searchConfig.filters &&
      configure({
        disjunctiveFacetsRefinements: {
          ...searchConfig.filters
        }
      })
  ]);

  search.start();

  /**
   * GTM Tracking
   * #TODO: copy over from instantSearch.ts and make specific to case studies
   */
};

export default initNwoCaseStudiesListing;
